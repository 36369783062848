import React from 'react';

import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLongLeftIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/solid';

import {
  apiCreatePMA,
  apiSendUserInvitation,
} from '../../../core/api';
import {
  apiKeyShan,
  mapOptions,
} from '../../../core/plugins/config';
import { signUp } from '../../../core/plugins/Entry';
import {
  convertAustralianNumber,
  isAustralianNumber,
} from '../../../core/plugins/validator';
function AddAgencyAdmin() {
  const [value, setValue] = React.useState(false)

  const [step, setStep] = React.useState(0)

  const clearValue = () => {
    setValue(false)
    setStep(0)
  }

  const navigation = useNavigate()

  const routeTo = (url) => {
    navigation(url)
  }

  const [valueOk, setOk] = React.useState("ok")

  const switchOk = (v) => {
    setOk(v)
  }

  const [show, setModal] = React.useState(false)
  const handleClose = () => {
    setModal(false)
  }

  const handleShow = () => {
    setModal(true)
  }

  const [payload, setPayload] = React.useState({
    CompanyName: "",
    AgencyAdminName: "",
    Email: "",
    Mobile: "",
    Address: "",
    PMAID: "",
    Password: "Password@123"
  })

  const [showPassword, setShowPassword] = React.useState(false)

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [loading, setLoading] = React.useState(false)
  const submitAgencyAdmin = (e) => {
    e.preventDefault()
    if (payload.Mobile.length > 0 && !isAustralianNumber(payload.Mobile)) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    setLoading(true)
    signUp({
      email: payload.Email,
      name: payload.AgencyAdminName,
      phone_number: convertAustralianNumber(payload.Mobile),
      password: payload.Password
    }).then((response) => {
      if (response) {
        apiCreatePMA({
          CompanyName: payload.CompanyName,
          AgencyAdminName: payload.AgencyAdminName,
          Email: payload.Email,
          Mobile: convertAustralianNumber(payload.Mobile),
          Address: payload.Address,
          PMAID: response.userSub
        }).then((response) => {
          handleShow()
          setLoading(false)

          apiSendUserInvitation({
            UserName: payload.AgencyAdminName,
            Password: payload.Password,
            Email: payload.Email
          })
        })
      } else {
        setLoading(false)
      }
    })
  }

  const onPlaceSelected = (place) => {
    if (place && place?.formatted_address) {
      setPayload((prevState) => ({
        ...prevState,
        Address: place?.formatted_address
      }))
    }
  }

  const generatePassword = (e) => {
    e.preventDefault()
    let length = 11
    var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVW@$%[]?!XYZ0123456789"
    var password = ""

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length)
      password += charset.charAt(randomIndex)
    }

    setPayload((prevState) => ({
      ...prevState,
      Password: password
    }))
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-small"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <CheckCircleIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Done</h4>
          <p>
            Agency admin <br />
            created successfully
          </p>
          <div className="spacer-lg"></div>
          <div className="input-element text-center">
            <button
              type="submit"
              className="primary-button-small"
              onClick={() => {
                handleClose()
                routeTo("/ia/agency-admin-list")
              }}
            >
              <div className="button-text">Ok</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 position-relative">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/ia/agency-admin-list")}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className="text-left">Add Agency Admin</h2>
              </div>

              {value && value !== "no" && (
                <div className="ticket-item">
                  <p>{value} assigned</p>
                  <div onClick={clearValue}>
                    <XMarkIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>

              <>
                <form onSubmit={submitAgencyAdmin}>
                  <div className="input-element">
                    <div className="input-normal">
                      <label>Agency name</label>
                      <input
                        name="CompanyName"
                        onChange={onChange}
                        autoFocus
                        required
                        type="text"
                        placeholder="Agency name"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Agency admin name</label>
                      <input
                        name="AgencyAdminName"
                        onChange={onChange}
                        required
                        type="text"
                        placeholder="Agency admin name"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Phone number</label>
                      <input
                        name="Mobile"
                        onChange={onChange}
                        type="text"
                        maxLength={12}
                        required
                        placeholder="Phone number"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Email</label>
                      <input
                        name="Email"
                        onChange={onChange}
                        type="email"
                        required
                        placeholder="Email address"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Address</label>
                      <Autocomplete
                        options={mapOptions}
                        required
                        apiKey={apiKeyShan}
                        onPlaceSelected={(place) => onPlaceSelected(place)}
                      />
                    </div>
                  </div>

                  <div className="username-password">
                    <div className="input-element">
                      <div className="input-normal">
                        <label>
                          Username
                          <span>
                            <small>
                              email will be selected as default username
                            </small>
                          </span>
                        </label>
                        <input
                          value={payload.Email}
                          name="Email"
                          type="email"
                          required
                          disabled
                          placeholder="Username"
                        ></input>
                      </div>
                    </div>

                    <div className="input-element">
                      <div className="input-normal">
                        <label>Password</label>
                        <input
                          value={payload.Password}
                          name="password"
                          required
                          type={showPassword ? "text" : "password"}
                          onChange={onChange}
                          placeholder="Password"
                        ></input>
                        <div
                          className="password-toggle"
                          onClick={togglePassword}
                        >
                          {!showPassword && <EyeSlashIcon />}
                          {showPassword && <EyeIcon />}
                        </div>
                      </div>
                    </div>

                    <div className="input-element">
                      <button
                        type="button"
                        className="primary-button primary-button-small generate-button no-animation"
                        onClick={generatePassword}
                      >
                        <div className="button-text"> Generate Password</div>
                      </button>
                    </div>
                  </div>

                  <div className="spacer-sm"></div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="input-element text-center">
                      <button
                        type="submit"
                        className="primary-button primary-button-small no-animation"
                      >
                        <div className="button-text">
                          {loading ? <Spinner size="sm" /> : "Save Details"}
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddAgencyAdmin
