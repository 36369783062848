import React from 'react';

import {
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ArrowRightIcon,
  EnvelopeIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { UserIcon as UserIconSolid } from '@heroicons/react/24/solid';

import {
  changePassword,
  forgotPassword,
} from '../../core/plugins/Entry';
import AuthLayout from '../layouts/AuthLayout';
function ForgotPassword() {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [payload, setPayload] = React.useState(false)

  const [code, setCode] = React.useState({
    username: "",
    code: "",
    new_password: ""
  })

  const onChange = (e) => {
    setPayload(e.target.value)
  }
  const forgotPasswordSendEmail = (e) => {
    e.preventDefault()
    forgotPassword(payload).then((response) => {
      setShowConfirmUser(true)
    })
  }

  const formSubmitChangePassword = (e) => {
    e.preventDefault()
    changePassword({
      username: payload,
      code: code.code,
      new_password: code.new_password
    }).then((response) => {
      toast.success("Password Changed")

      setTimeout(() => {
        setShowConfirmUser(false)
        window.location = "/"
      }, 800)
    })
  }

  const handleConfirmUserModalClose = () => {
    setShowConfirmUser(false)
  }

  const onChangePayload = (e) => {
    setCode((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [showConfirmUser, setShowConfirmUser] = React.useState(false)
  return (
    <>
      <Modal
        show={showConfirmUser}
        onHide={handleConfirmUserModalClose}
        backdrop="static"
        className="confirm-modal"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <EnvelopeIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Change Password</h4>
          <p>
            Please enter your confirmation code and continue <br />
            <small>Confirmation code is sent to your email address</small>
          </p>

          <form onSubmit={formSubmitChangePassword}>
            <div className="input-element">
              <div className="input-wrapper">
                <input
                autocomplete="off"
                  style={{
                    paddingLeft: 0
                  }}
                  autoFocus
                  name="code"
                  type="text"
                  maxLength={6}
                  placeholder="Confirmation Code"
                  required
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-wrapper">
                <input
                     autocomplete="off"
                  style={{
                    paddingLeft: 0
                  }}
                  autoFocus
                  name="new_password"
                  type="password"
                  placeholder="New Password"
                  required
                  minLength="6"
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className="input-element text-center">
              <button type="submit" className="primary-button-small">
                <div className="button-text">Change Password</div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <AuthLayout>
        <div className="container login-form-page ">
          <div className="d-flex flex-column justify-content-center">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="main-icon">
                  <UserIconSolid />
                </div>
                <h2 className="text-center">Forgot Password ? </h2>
                <h4>Please enter email (username) to reset your password</h4>
                <div className="spacer-lg"></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <form onSubmit={forgotPasswordSendEmail}>
                  <div className="input-element">
                    {/* <label>Username</label> */}
                    <div className="input-wrapper">
                      <div className="left-icon">
                        <UserIcon />
                      </div>
                      <input
                        onChange={onChange}
                        type="email"
                        placeholder="Email Address"
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="spacer-sm"></div>

                  <div className="input-element text-center">
                    <button
                      disabled={loading}
                      type="submit"
                      className="primary-button"
                    >
                      <div className="button-text">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Reset Password"
                        )}
                      </div>
                      <div className="right-icon">
                        <ArrowRightIcon />
                      </div>
                    </button>
                  </div>
                </form>
                <div className="spacer-lg"></div>
                <p className="small text-center">PM Assist by</p>
                <p className="small text-orange text-center">
                  Tenancy Skills Institute
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  )
}

export default ForgotPassword
