import React from 'react';

import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLeftIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/solid';

import {
  apiCreatePM,
  apiSendUserInvitation,
} from '../../../core/api';
import {
  getUser,
  signUp,
} from '../../../core/plugins/Entry';
import {
  convertAustralianNumber,
  isAustralianNumber,
} from '../../../core/plugins/validator';
function AddPropertyManager() {
  const [value, setValue] = React.useState(false)

  const [step, setStep] = React.useState(0)

  const clearValue = () => {
    setValue(false)
    setStep(0)
  }

  const generatePassword = (e) => {
    e.preventDefault()
    let length = 11
    var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVW@$%[]?!XYZ0123456789"
    var password = ""

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length)
      password += charset.charAt(randomIndex)
    }

    setPayload((prevState) => ({
      ...prevState,
      Password: password
    }))
  }

  const [showPassword, setShowPassword] = React.useState(false)

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  //  apiGetAllIssues2()
  const navigation = useNavigate()

  const routeTo = (url) => {
    navigation(url)
  }

  const [valueOk, setOk] = React.useState("ok")

  const switchOk = (v) => {
    setOk(v)
  }

  const [show, setModal] = React.useState(false)
  const handleClose = () => {
    setModal(false)
  }

  const handleShow = (e) => {
    e.preventDefault()
    submit()
  }

  const [payload, setPayload] = React.useState({
    UserName: "",
    Email: "",
    Mobile: "",
    Name: "",
    PMAID: getUser().UUID, //sample id to validate the payload
    Password: "Password@123"
  })
  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  const [loading, setLoading] = React.useState(false)
  const submit = (e) => {
    e.preventDefault()

    if (payload.Mobile.length > 0 && !isAustralianNumber(payload.Mobile)) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    setLoading(true)
    signUp({
      email: payload.Email,
      name: payload.Name,
      phone_number: convertAustralianNumber(payload.Mobile),
      password: payload.Password
    }).then((response) => {
      if (response && response.user && response.user.username) {
        apiCreatePM({
          UserName: payload.Email,
          Email: payload.Email,
          Mobile: convertAustralianNumber(payload.Mobile),
          Name: payload.Name,
          PMAID: payload.PMAID,
          Password: payload.Password,
          PMID: response.userSub
        })
          .then((response) => {
            setModal(true)

            apiSendUserInvitation({
              UserName: payload.Name,
              Password: payload.Password,
              Email: payload.Email
            })
          })
          .catch((error) => {
            toast.error(error?.message)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    })
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-small"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <CheckCircleIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Done</h4>
          <p>
            Property Manager <br />
            Added successfully
          </p>
          <div className="spacer-lg"></div>
          <div className="input-element text-center">
            <button
              type="submit"
              className="primary-button-small"
              onClick={() => {
                handleClose()
                routeTo("/aa/pm-list")
              }}
            >
              <div className="button-text">Ok</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 position-relative">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/aa/pm-list")}
                >
                  <ArrowLeftIcon />
                </div>
                <h2 className="text-left">Add Property Managers</h2>
              </div>

              {value && value !== "no" && (
                <div className="ticket-item">
                  <p>{value} assigned</p>
                  <div onClick={clearValue}>
                    <XMarkIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>

              <>
                <form>
                  <div className="input-element">
                    <div className="input-normal">
                      <label>Property Manager Name</label>
                      <input
                        onChange={onChange}
                        autoFocus
                        required
                        name="Name"
                        type="text"
                        placeholder="Property Manager Name"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Phone Number</label>
                      <input
                        required
                        name="Mobile"
                        onChange={onChange}
                        type="text"
                        placeholder="Phone Number"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Email</label>
                      <input
                        onChange={onChange}
                        required
                        name="Email"
                        type="email"
                        placeholder="Email address"
                      ></input>
                    </div>
                  </div>

                  <div className="username-password">
                    <div className="input-element">
                      <div className="input-normal">
                        <label>
                          Username
                          <span>
                            <small>
                              email will be selected as default username
                            </small>
                          </span>
                        </label>
                        <input
                          value={payload.Email}
                          name="UserName"
                          type="email"
                          required
                          disabled
                          onChange={onChange}
                          placeholder="Username"
                        ></input>
                      </div>
                    </div>

                    <div className="input-element">
                      <div className="input-normal">
                        <label>Password</label>
                        <input
                          value={payload.Password}
                          name="Password"
                          required
                          type={showPassword ? "text" : "password"}
                          onChange={onChange}
                          placeholder="Password"
                        ></input>
                        <div
                          className="password-toggle"
                          onClick={togglePassword}
                        >
                          {!showPassword && <EyeSlashIcon />}
                          {showPassword && <EyeIcon />}
                        </div>
                      </div>
                    </div>

                    <div className="input-element">
                      <button
                        type="button"
                        className="primary-button primary-button-small generate-button no-animation"
                        onClick={generatePassword}
                      >
                        <div className="button-text"> Generate Password</div>
                      </button>
                    </div>
                  </div>

                  <div className="spacer-sm"></div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="input-element text-center">
                      <button
                        type="submit"
                        className="primary-button primary-button-small no-animation"
                        onClick={submit}
                      >
                        <div className="button-text">
                          {loading ? <Spinner size="sm" /> : "Save Information"}
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default AddPropertyManager
