import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';

import moment from 'moment';
import {
  Modal,
  Spinner,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronUpIcon,
  FlagIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLeftIcon,
  UserIcon,
} from '@heroicons/react/24/solid';

import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {
  apiGenerateReport,
  apiGetAllInquiresIA,
  apiGetAllIssues,
  apiGetAllPMA,
  apiGetAllPMs,
} from '../../../core/api';
import { UserHeader } from '../../shared';
function AgencySupportList() {
  const navigate = useNavigate()

  const routeTo = (url) => {
    navigate(url)
  }

  const [pms, setPms] = React.useState(false)
  const [pmas, setPmas] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [inquiries, setInquiry] = React.useState(false)
  const [issues, setIssues] = React.useState(false)
  React.useEffect(() => {
    apiGetAllPMs().then((response) => {
      setPms(response)
    })

    apiGetAllPMA().then((response) => {
      setPmas(response)
    })

    apiGetAllIssues().then((response) => {
      setIssues(response)
    })

    setLoading(true)
    apiGetAllInquiresIA().then((response) => {
      if (response) {
        setLoading(false)
        setInquiry(response)
      }
    })
  }, [])

  const clearAll = () => {
    apiGetAllInquiresIA().then((response) => {
      if (response) {
        setLoading(false)
        setInquiry(response)
        setFilterModal(false)
      }
    })
  }

  const getIssueTitle = (inq) => {
    const title = issues && issues.filter((i) => i.IssueID === inq.IssueTitle)
    if (title && title.length) {
      return title[0].Title
    } else {
      return inq.IssueTitle
    }
  }

  const [filterModal, setFilterModal] = React.useState(false)

  const openFilter = () => {
    setFilterModal(true)
  }

  const [progress, setProgress] = React.useState(false)
  const generateReport = () => {
    setProgress(true)
    apiGenerateReport().then((response) => {
      setProgress(false)
      window.open(response)
    })
  }

  const [sIndex, setSindex] = React.useState(false)
  const [allfilters, setAllFilters] = React.useState({
    start_date: new Date(),
    end_date: new Date(),
    PMID: "",
    PMAID: ""
  })

  const setFiltersPMA = (e) => {
    setAllFilters((prevState) => ({
      ...prevState,
      PMAID: e.target.value
    }))
  }
  const setFilters = (e) => {
    setAllFilters((prevState) => ({
      ...prevState,
      PMID: e.target.value
    }))
  }

  const setEndDate = (e) => {
    setAllFilters((prevState) => ({
      ...prevState,
      end_date: e
    }))
  }

  const setStartDate = (e) => {
    setAllFilters((prevState) => ({
      ...prevState,
      start_date: e
    }))
  }

  const filterTickets = () => {
    let filtered =
      inquiries &&
      inquiries.length &&
      inquiries.filter((q) => {
        return (
          (moment(q.CreateDateTime).isSameOrBefore(allfilters.end_date) &&
            moment(q.CreateDateTime).isSameOrAfter(allfilters.start_date)) ||
          (q.PMID === allfilters.PMID && q.PMAID === allfilters.PMAID)
        )
      })
    setInquiry(filtered)
  }
  const [currentPage, setCurrentPage] = React.useState(1)
  const nextPage = () => {
    if (currentPage !== pages.length) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const toPage = (i) => {
    setCurrentPage(i)
  }

  React.useEffect(() => {
    apiGetAllInquiresIA(currentPage).then((response) => {
      if (response) {
        setLoading(false)
        setInquiry(response)
        setFilterModal(false)
      }
    })
  }, [currentPage])

  const [pages, setPages] = React.useState(false)
  React.useEffect(() => {
    if (inquiries) {
      setPages(new Array(Math.ceil(inquiries.size / 10)).fill(0))
    }
  }, [inquiries])
  return (
    <>
      <DefaultLayout>
        <Modal
          className="filter-modal"
          show={filterModal}
          onHide={() => {
            setFilterModal(false)
          }}
        >
          <Modal.Body>
            <h2>Filter by</h2>

            <div className="d-flex filter-date-wapper justify-content-between">
              <div className="datepicker-wrapper">
                <div className="picker-icon">
                  <CalendarDaysIcon />
                </div>

                <div className="date-wrapper">
                  <small>From date</small>
                  <DatePicker
                    selected={allfilters.start_date}
                    onChange={setStartDate}
                  />
                </div>
              </div>

              <div className="datepicker-wrapper">
                <div className="picker-icon">
                  <CalendarDaysIcon />
                </div>
                <div className="date-wrapper">
                  <small>To date</small>
                  <DatePicker
                    selected={allfilters.end_date}
                    onChange={setEndDate}
                  />
                </div>
              </div>
            </div>
            <div className="spacer-md"></div>
            <div>
              <form>
                <div className="input-element">
                  <div className="input-normal">
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 14
                      }}
                    >
                      Property Managers
                    </div>

                    <select onChange={setFilters}>
                      <option>Select Property Managers</option>
                      {pms &&
                        pms.map((pm) => (
                          <option value={pm.PMID}>{pm.Name}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="input-element">
                  <div className="input-normal">
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 14
                      }}
                    >
                      Agencies
                    </div>

                    <select onChange={setFiltersPMA}>
                      <option>Select Assigned Agency</option>
                      {pmas &&
                        pmas.map((PMA) => (
                          <option value={PMA.PMAID}>{PMA.CompanyName}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </form>

              <div className="input-element text-center">
                <button
                  className="primary-button no-animation"
                  onClick={() => {
                    setFilterModal(false)
                    filterTickets()
                  }}
                >
                  <div className="button-text">Filter</div>
                </button>
                <div className="spacer-sm"></div>
                <div
                  onClick={clearAll}
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer"
                  }}
                >
                  Clear Filters{" "}
                  {loading ? <Spinner animation="border" size="sm" /> : ""}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="container inner-page ">
          <div className="spacer-lg"></div>
          <div className="d-flex flex-column">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <UserHeader admin="Incommunity Admin" />
                <div className="spacer-lg"></div>
                <div
                  className="user-button user-button-small user-button-small-special"
                  onClick={() => routeTo("/ia/agency-admin-list")}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-left fill-gradient-orange">
                      <UserIcon />
                    </div>
                    <div className="user-button-text">Agency Admins</div>
                  </div>
                  <div className="icon-right">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div
                  className="user-button user-button-small user-button-small-special"
                  onClick={() => routeTo("/ia/support-service")}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-left fill-gradient-orange">
                      <UserIcon />
                    </div>
                    <div className="user-button-text">Support Service</div>
                  </div>
                  <div className="icon-right">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="spacer-sm"></div>
                <div className="accordian-list">
                  <div className="d-flex sub-title-with-icon align-items-center justify-content-between">
                    <div className="d-flex">
                      <div className="icon-left">
                        <FlagIcon />
                      </div>
                      <h3>
                        Enquires{" "}
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                        <h6>
                          <small>
                            {inquiries &&
                              inquiries.data &&
                              inquiries.data.length > 0 &&
                              !loading &&
                              inquiries.data.length + " tickets found"}
                          </small>
                        </h6>
                      </h3>
                    </div>
                    <div>
                      <div className="input-element text-center d-flex">
                        <button
                          disabled={!inquiries && !inquiries.length}
                          className="primary-button primary-button-xsmall no-animation"
                          onClick={openFilter}
                          style={{
                            marginRight: 12
                          }}
                        >
                          <div className="right-icon">
                            <FunnelIcon />
                          </div>
                          <div className="button-text">Filter</div>
                        </button>

                        <div className="download-button">
                          <button
                            onClick={generateReport}
                            disabled={!inquiries && !inquiries.length}
                          >
                            {progress ? (
                              <Spinner size="sm" />
                            ) : (
                              <ArrowDownTrayIcon />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="spacer-sm"></div>

                  <div className="">
                    <div>
                      {inquiries &&
                        inquiries.data &&
                        inquiries.data.length > 0 &&
                        inquiries.data.map((inq, i) => (
                          <Disclosure
                            as="div"
                            className={
                              i === sIndex ? "acc-wrapper open" : "acc-wrapper"
                            }
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className="d-flex acc-wrapper-button"
                                  onClick={() => {
                                    setSindex(i)
                                  }}
                                >
                                  <div>
                                    <span className="text-orange">
                                      {getIssueTitle(inq)} -
                                      {moment(inq.CreateDateTime).format(
                                        "YYYY - MMM - DD"
                                      )}
                                    </span>{" "}
                                  </div>
                                  <ChevronUpIcon
                                    className={`${open ? "rotate-180" : ""}`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="acc-panel">
                                  <p>
                                    <span className="text-orange">Date</span>
                                    <br />
                                    <span>
                                      {moment(inq.CreateDateTime).format(
                                        "YYYY - MM - DD"
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="text-orange">
                                      Issue Title
                                    </span>
                                    <br />
                                    <span>{getIssueTitle(inq)}</span>
                                  </p>

                                  <p>
                                    <span className="text-orange">
                                      Tenant Name
                                    </span>
                                    <br />
                                    <span>{inq.TenantName}</span>
                                  </p>

                                  <p>
                                    <span className="text-orange">
                                      Support service
                                    </span>
                                    <br />
                                    <span>{inq.SWName}</span>
                                  </p>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                      {inquiries &&
                        inquiries.data &&
                        inquiries.data.length === 0 && (
                          <div className="empty-list-data">
                            <div className="spacer-sm"></div>
                            <h4>No enquires found</h4>
                          </div>
                        )}

                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="input-element text-center"
                          style={{ marginRight: 24 }}
                        >
                          <button
                            style={{
                              minWidth: 36,
                              padding: "0px 12px"
                            }}
                            className="primary-button primary-button-small"
                            onClick={() => {
                              prevPage()
                            }}
                          >
                            <div className="right-icon">
                              <ArrowLeftIcon />
                            </div>
                          </button>
                        </div>

                        <div className="d-flex">
                          {pages &&
                            pages.map((page, index) => (
                              <div
                                className="input-element text-center"
                                style={{ marginRight: 24 }}
                              >
                                <button
                                  style={{
                                    minWidth: 36,
                                    padding: "0px 12px"
                                  }}
                                  className="primary-button primary-button-small"
                                  onClick={() => {
                                    toPage(index + 1)
                                  }}
                                >
                                  <div className="right-icon">{index + 1}</div>
                                </button>
                              </div>
                            ))}
                        </div>
                        <div className="input-element text-center">
                          <button
                            style={{
                              minWidth: 36,
                              padding: "0px 12px"
                            }}
                            className="primary-button primary-button-small"
                            onClick={() => {
                              nextPage()
                            }}
                          >
                            <div className="right-icon">
                              <ArrowRightIcon />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "center"
            }}
          >
            <a
              className="d-block"
              href="/"
              style={{
                fontSize: 10,
                color: "#fff"
              }}
            >
              PM Assist
            </a>
          </div>
        </div>
      </DefaultLayout>
    </>
  )
}

export default AgencySupportList
