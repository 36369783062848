import React from 'react';
import { ROUTES } from '../../core/routes';

const Layout = ({ children }) => {
  return (
    <>
      {children}
      <footer className="footer-layout text-center privacy-terms-link-wrapper">
        <a 
          href="/privacy-policy"
        >
          Privacy
        </a>
        &nbsp;|&nbsp;
        <a 
          href='/terms-and-conditions'
        >
          Terms & Conditions
        </a>
      </footer>
    </>
  );
};

export default Layout;