import React from "react"

import { useNavigate } from "react-router-dom"

import { signOut } from "../../core/plugins/Entry"
function Logout() {
  const navigate = useNavigate()
  React.useEffect(() => {
    signOut()
    setTimeout(() => {
      navigate("/")
    }, 300)
  }, [])

  return <></>
}

export default Logout
