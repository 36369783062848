import React from "react"

import moment from "moment"
import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"
import { ArrowRightIcon, FlagIcon } from "@heroicons/react/24/outline"

import { apiGetAllInquires, apiGetAllIssues, apiGetPM } from "../../../core/api"
import { getUser } from "../../../core/plugins/Entry"
import { ROUTES } from "../../../core/routes"
import DefaultLayout from "../../layouts/DefaultLayout"
import { UserHeader } from "../../shared"
const PMDashboard = () => {
  const navigate = useNavigate()

  const routeTo = (url) => {
    navigate(url)
  }
  const [user, setUser] = React.useState(getUser())
  const [issues, setIssues] = React.useState(false)
  const [inquiries, setInquiry] = React.useState(false)
  const [loading, setLoaing] = React.useState(false)
  React.useEffect(() => {
    setLoaing(true)

    apiGetPM(getUser().UUID)
      .then((response) => {
        if (response && response.PMID) {
          apiGetAllIssues().then((response) => {
            setIssues(response)
          })

          apiGetAllInquires().then((response) => {
            if (response) {
              setLoaing(false)
              setInquiry(response)
            }
          })
        } else {
          navigate("/logout")
        }
      })
      .finally(() => {
        setLoaing(false)
      })
  }, [])

  const getIssueTitle = (inq) => {
    const title = issues && issues.filter((i) => i.IssueID === inq.IssueTitle)
    if (title && title.length) {
      return title[0].Title
    } else {
      return inq.IssueTitle
    }
  }

  const [sIndex, setSIndex] = React.useState(false)
  return (
    <>
      <DefaultLayout>
        <div className="container inner-page ">
          <div className="spacer-lg"></div>
          <div className="d-flex flex-column">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <UserHeader admin="Property Manager" />
                <div className="spacer-sm"></div>
                <div
                  className="user-button user-button-small"
                  onClick={() => routeTo(ROUTES.PM_RAISE_ISSUE)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-left">
                      <FlagIcon />
                    </div>
                    <div className="user-button-text">Raise an issue</div>
                  </div>
                  <div className="icon-right">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="spacer-lg"></div>
                <div className="accordian-list">
                  <div className="d-flex sub-title-with-icon align-items-center">
                    <div className="icon-left">
                      <FlagIcon />
                    </div>
                    <h3>
                      Issues History {loading ? <Spinner size="sm" /> : ""}
                    </h3>
                    <div className="spacer-md"></div>
                  </div>

                  <div className="">
                    <div>
                      {inquiries &&
                        inquiries.map((inq, i) => (
                          <Disclosure
                            as="div"
                            key={inq.InquiryID}
                            className={
                              sIndex === i ? "acc-wrapper open" : "acc-wrapper"
                            }
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className="d-flex align-items-center justify-content-between acc-wrapper-button"
                                  onClick={() => {
                                    setSIndex(i)
                                  }}
                                >
                                  <span className="text-orange flex-grow-1">
                                    {inq.IssueTitle} -{" "}
                                    {moment(inq.CreateDateTime).format("YYYY-MMM-DD")}
                                  </span>

                                  <span className="inline-flex items-center">
                                    {inq.TenantConsent === "Yes" && (
                                      <span className="status-circle status-green"></span>
                                    )}
                                    {inq.TenantConsent === "No" && (
                                      <span className="status-circle status-red"></span>
                                    )}
                                    {inq.TenantConsent === "Waiting" && (
                                      <span className="status-circle status-yellow"></span>
                                    )}
                                  </span>

                                  <ChevronUpIcon className={`h-5 w-5 ${open ? "transform rotate-180" : ""}`} />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                  className={
                                    open ? "acc-panel open" : "acc-panel"
                                  }
                                >
                                  <p>
                                    <span className="text-orange">
                                      Created Date
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.CreateDateTime
                                        ? moment(inq?.CreateDateTime).format(
                                            "YYYY-MMM-DD"
                                          )
                                        : "-"}
                                    </span>
                                  </p>

                                  <p>
                                    <span className="text-orange">
                                      Contextual Notes
                                    </span>
                                    <br />
                                    <span>{inq?.ContextualNotes}</span>
                                  </p>

                                  <p>
                                    <span className="text-orange">
                                      Tenant Name
                                    </span>
                                    <br />
                                    <span>{inq?.TenantName}</span>
                                  </p>
                                  {inq?.TenantEmail && (
                                    <p>
                                      <span className="text-orange">
                                        Tenant Email
                                      </span>
                                      <br />
                                      <span>{inq?.TenantEmail}</span>
                                    </p>
                                  )}

                                  {inq.TenantMobile && (
                                    <p>
                                      <span className="text-orange">
                                        Tenant Phone Number
                                      </span>
                                      <br />
                                      <span>{inq?.TenantMobile}</span>
                                    </p>
                                  )}

                                  {inq?.TenantConsent && (
                                    <p>
                                      <span className="text-orange">
                                        Tenant Consent
                                      </span>
                                      <br />
                                      <span>{inq?.TenantConsent}</span>
                                    </p>
                                  )}

                                  <p>
                                    <span className="text-orange">
                                      Support Service
                                    </span>
                                    <br />
                                    <span>{inq?.SWName}</span>
                                  </p>
                                  {inq?.SWEmail && (
                                    <p>
                                      <span className="text-orange">
                                        Support Email
                                      </span>
                                      <br />
                                      <span>{inq?.SWEmail}</span>
                                    </p>
                                  )}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                      {inquiries && inquiries.length === 0 && (
                        <div className="empty-list-data">
                          <div className="spacer-sm"></div>
                          <h4>No issues found</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  )
}

export default PMDashboard
