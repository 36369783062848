import { httpClient, httpClientAxios } from "../interceptors"
import { getUser } from "../plugins/Entry"
// const API = {
//   GET_ALL_PM: (agency_id) => `/PM/GetAllPM?ID=${agency_id}`,
//   GET_PM: (pm_id) => `/PM/GET_PM?ID=${pm_id}`,
//   CREATE_PM: `/PM/CreatePM`,
//   DELETE_PM: `/PM/DeletePM`,
//   UPDATE_PM: `/PM/UpdatePM`,

//   AGENCY_CREATE: `/PMAgency/CreatePMA`,
//   AGENCY_GET_ALL: `/PMAgency/GetAllPMA`,
//   AGENCY_DELETE: `/PMAgency/DeletePMA`,
//   AGENCY_UPDATE: `/PMAgency/UpdatePMA`,

//   SUPPORT_SERVICES_GET_ALL: `/SupportWorkers/GetAllSupportWorkers`,
//   SUPPORT_SERVICES_UPDATE: `/SupportWorkers/UpdateSupportWorkers`,
//   SUPPORT_SERVICES_DELETE: `/SupportWorkers/DeleteSupportWorkers`,
//   SUPPORT_SERVICES_GET_ISSUES: (SID) =>
//     `/SupportWorkers/GetSupportWorkerIssues?ID=${SID}`,

//   TENANT_CREATE: ``,
//   TENANT_GET_ALL: (pm_id) => `/Tenant/GetAllTenant?ID=${pm_id}`,

//   ENQUIRY_CREATE: `/Inquiry/CreateInquiry`,
//   ENQUIRY_GET_ALL: (pm_id) => `/Inquiry/GetAllEnquiries?ID=${pm_id}`,
//   ENQUIRY_GENERATE: (pm_id) => `/Inquiry/GenerateReport${pm_id}`,

//   ISSUES_GET_ALL: `/Issues/GetAllIssues`
// }

const API = {
  ISSUES_GET_ALL: "/issues",
  ISSUES_GET_ALL_2: `/Issues/GetAllIssues`,

  ENQUIRY_CREATE: `/Inquiry`,
  ENQUIRY_GET_ALL: `/Inquiry`,
  ENQUIRY_GENERATE: (pm_id) => `/Inquiry?id=${pm_id}`,

  SUPPORT_SERVICES_GET_ALL: `/SupportWorkers`,
  SUPPORT_SERVICES_UPDATE: `/SupportWorkers`,
  SUPPORT_SERVICES_DELETE: `/SupportWorkers`,
  SUPPORT_SERVICES_GET_ISSUES: (SID) => `/SupportWorkers?id=${SID}`
}

export const apiGetAllIssues = async () => {
  // try {
  //   const response = await httpClient.get(API.ISSUES_GET_ALL)
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await httpClientAxios.get("/Issues/GetAllIssues")
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllInquires = async () => {
  try {
    const response = await httpClientAxios.get(
      `/Inquiry/GetAllEnquiries?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllInquiresIA = async (currentPage = 1) => {
  try {
    const response = await httpClientAxios.get(
      `/Inquiry/GetAllEnquiriesIA?pagesize=${10}&page=${currentPage}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const apiGetSupportServices = async () => {
  try {
    const response = await httpClientAxios.get(
      "/SupportWorkers/GetAllSupportWorkers"
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGenerateReport = async () => {
  try {
    const response = await httpClientAxios.get("/Inquiry/GenerateReport")
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetSupportServicesDistance = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      "/SupportWorkers/GetDistance",
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiCreateInquiry = async (payload) => {
  try {
    const response = await httpClient.post(API.ENQUIRY_CREATE, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiCreateInquiry2 = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      "/Inquiry/CreateInquiry",
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiCreatePM = async (payload) => {
  // try {
  //   const response = await httpClient.post("/property_managers_all", payload)
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.post("/PM/CreatePM", payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdatePM = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/PM/UpdatePM`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllPM = async () => {
  // try {
  //   const response = await httpClient.get("/property_managers_all")
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.get(
      `/PM/GetAllPM?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
  ///
}

export const apiGetAllPMs = async () => {
  // try {
  //   const response = await httpClient.get("/property_managers_all")
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.get(`/PM/GetAllPM`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
  ///
}

export const apiDeletePM = async (id) => {
  try {
    const response = await httpClientAxios.delete(`/PM/DeletePM?ID=${id}`)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiDeletePMA = async (id) => {
  try {
    const response = await httpClientAxios.delete(
      `/PMAgency/DeletePMAgency?ID=${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const apiGetAllPMA = async () => {
  try {
    const response = await httpClientAxios.get(`/PMAgency/GetAllPMA`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdatePMA = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/PMAgency/UpdatePMA`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiCreatePMA = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/PMAgency/CreatePMA`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

//SupportWorkers

export const apiCreateSupportWorkers = async (payload) => {
  // try {
  //   const response = await httpClient.post(`/SupportWorkers`, payload)
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.post(
      `/SupportWorkers/CreateSupportWorker`,
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetSupportWorkers = async () => {
  try {
    const response = await httpClient.get(`/SupportWorkers`)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiDeleteSupportWorkers = async (id) => {
  // try {
  //   const response = await httpClient.delete(`/SupportWorkers/${id}`)
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.delete(
      `/SupportWorkers/DeleteSupportWorkers?ID=${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdateSupportWorkers = async (payload) => {
  // try {
  //   const response = await httpClient.put(`/SupportWorkers/${id}`, payload)
  //   return response?.data
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await httpClientAxios.post(
      `/SupportWorkers/UpdateSupportWorkers`,
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

//get PMA
export const apiGetPMA = async (PMAID) => {
  try {
    const response = await httpClientAxios.get(`/PMAgency/GetPMA?ID=${PMAID}`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

//get PM
export const apiGetPM = async (PMID) => {
  try {
    const response = await httpClientAxios.get(`/PM/GetPM?ID=${PMID}`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}
//get PM
export const apiGetIA = async (IAID) => {
  try {
    const response = await httpClientAxios.get(
      `/admin/getia?ID=${IAID}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiSendUserInvitation = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      `/Invitation/InviteUser`,
      payload
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const apiVerifyUserCode = async (userName, challengeAnswer) => {
  try {
    const response = await httpClientAxios.get(
      `/Auth/VerifyChallenge?userName=${encodeURIComponent(userName)}&challengeAnswer=${encodeURIComponent(challengeAnswer)}`
    )
    return response?.data;
  } catch (error) {
    console.log(error)
  }
}
