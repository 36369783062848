import React from "react"

import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"
import { ArrowRightIcon, FlagIcon } from "@heroicons/react/24/outline"
import { UserIcon } from "@heroicons/react/24/solid"

import { apiGetAllInquires, apiGetPMA } from "../../../core/api"
import { getUser } from "../../../core/plugins/Entry"
import { UserHeader } from "../../shared"
function PropertyManagers() {
  const [inquiries, setInquiry] = React.useState(false)
  const [sIndex, setSIndex] = React.useState(false)
  React.useEffect(() => {
    apiGetPMA(getUser().UUID).then((response) => {
      if (response && response.PMAID) {
        apiGetAllInquires().then((response) => {
          setInquiry(response)
        })
      } else {
        toast.warn("Not a valid Agent Administrator")
        navigate(-1)
      }
    })
  }, [])

  const navigate = useNavigate()

  const routeTo = (url) => {
    navigate("/aa/pm-list")
  }

  return (
    <>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <UserHeader admin="Agency Admin" />
              <div className="spacer-lg"></div>
              <div className="user-button user-button-small" onClick={routeTo}>
                <div className="d-flex align-items-center">
                  <div className="icon-left">
                    <UserIcon />
                  </div>
                  <div className="user-button-text">Property Managers</div>
                </div>
                <div className="icon-right">
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>
              <div className="accordian-list">
                <div className="d-flex sub-title-with-icon align-items-center">
                  <div className="icon-left">
                    <FlagIcon />
                  </div>
                  <h3>Enquiries {!inquiries && <Spinner size="sm" />}</h3>
                </div>

                <div>
                  {inquiries &&
                    inquiries.map((inq, i) => (
                      <Disclosure
                        as="div"
                        className={
                          i === sIndex ? "acc-wrapper open" : "acc-wrapper"
                        }
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className="d-flex acc-wrapper-button"
                              onClick={() => {
                                setSIndex(i)
                              }}
                            >
                              <div>
                                <span className="text-orange">Ticket No:</span>
                                <span>{inq.IssueID}</span>
                              </div>
                              <ChevronUpIcon
                                className={`${open ? "rotate-180" : ""}`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="acc-panel">
                              <p>
                                <span className="text-orange">Date</span>
                                <br />
                                <span>29/06/2023</span>
                              </p>
                              <p>
                                <span className="text-orange">Issue Title</span>
                                <br />
                                <span>{inq.IssueTitle}</span>
                              </p>

                              <p>
                                <span className="text-orange">Tenent Name</span>
                                <br />
                                <span>{inq.TenantName}</span>
                              </p>
                              <p>
                                <span className="text-orange">Mobile</span>
                                <br />
                                <span>{inq?.Mobile}</span>
                              </p>
                              <p>
                                <span className="text-orange">Email</span>
                                <br />
                                <span>{inq?.Email}</span>
                              </p>

                              <p>
                                <span className="text-orange">
                                  Tenent Location
                                </span>
                                <br />
                                <span>*********</span>
                              </p>

                              <p>
                                <span className="text-orange">Issue</span>
                                <br />
                                <span>Issue information </span>
                              </p>

                              <p>
                                <span className="text-orange">Support</span>
                                <br />
                                <span>Support service name</span>
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}

                  {inquiries && inquiries.length === 0 && (
                    <div className="empty-list-data">
                      <div className="spacer-sm"></div>
                      <h4>No enquiries found</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default PropertyManagers
