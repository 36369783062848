import React from "react"

import moment from "moment"
import { Badge, Button, Modal, Spinner } from "react-bootstrap"
import Autocomplete from "react-google-autocomplete"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Disclosure } from "@headlessui/react"
import { ArrowLongLeftIcon, ChevronUpIcon } from "@heroicons/react/20/solid"
import {
  FlagIcon,
  MagnifyingGlassIcon,
  TrashIcon
} from "@heroicons/react/24/outline"
import { PencilIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid"

import {
  apiDeleteSupportWorkers,
  apiGetAllIssues,
  apiGetSupportServices,
  apiUpdateSupportWorkers
} from "../../../core/api"
import { apiKeyShan, mapOptions } from "../../../core/plugins/config"
import {
  convertAustralianNumber,
  isAustralianNumber
} from "../../../core/plugins/validator"
function SupportServiceList() {
  const navigation = useNavigate()

  const [deleteConfirmationModel,showDeleteConfirmation] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);

  const routeTo = (route) => {
    navigation(route)
  }
  const [payload, setPayload] = React.useState(false)
  const filter = (e) => {
    setPayload(e.target.value)
    let filtered = list.filter((item) => item.Name.includes(e.target.value))
    setList(filtered)

    if (e.target.value.length === 0) {
      clearFilters()
      setPayload(false)
      setList(defaultList)
    }
  }
  const [list, setList] = React.useState(false)
  const [defaultList, setDefaultList] = React.useState(false)

  const clearFilters = () => {
    setPayload(false)

    apiGetSupportServices().then((response) => {
      setLoading(false)
      const updatedList = getList(response)
      setList(updatedList)
      setDefaultList(updatedList)
      // setList(response)
      // setDefaultList(response)
    })
  }

  const [services, setServices] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isAcceptRef, setAcceptRef] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    apiGetSupportServices().then((response) => {
      setLoading(false)

      const updatedList = getList(response)
      setList(updatedList)
      setDefaultList(updatedList)
      // setList(response)
      // setDefaultList(response)
    })

    apiGetAllIssues().then((response) => {
      if (response) {
        setServices(response)
      }
    })
  }, [])

  const getList = (list) => {
    const updatedResponse = list.map((item) => {
      // if (!item.hasOwnProperty('IsAcceptRef')) {
      //   return { ...item, IsAcceptRef: false };
      // }
      // return item;
      return {
        ...item,
        IsAcceptRef: item.hasOwnProperty('IsAcceptRef') ? item.IsAcceptRef : false,
        Description: item.hasOwnProperty('Description') ? item.Description : "",
      };
    });
    const trueAcceptRef = updatedResponse.filter(item => item.IsAcceptRef);
    const falseAcceptRef = updatedResponse.filter(item => !item.IsAcceptRef);
    const sortedList = [...trueAcceptRef, ...falseAcceptRef]

    return sortedList;
  }

  const mapServices = (current_issues) => {
    let issues = JSON.parse(current_issues)
    let current_services = []
    if (services && issues.length) {
      services &&
        services.forEach((s) => {
          issues.forEach((i) => {
            if (s.IssueID === i) {
              current_services.push(s)
            }
          })
        })

      return current_services
    } else {
      return []
    }
  }

  const [user_location, setUserLocation] = React.useState(false)

  const onPlaceSelected = (place) => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address)

      setEditPayload((prevState) => ({
        ...prevState,
        Location: place.formatted_address
      }))
    }
  }

  const [editPayload, setEditPayload] = React.useState({
    Mobile: "",
    Name: "",
    Email: "",
    Address: "",
    Location: "",
    IssueID: [],
    IsAcceptRef: false,
    Description: ""
  })
  const [editLoader, setEditLoader] = React.useState(false)
  const [sIndex, setSIndex] = React.useState(false)
  const submitEdit = (e) => {
    e.preventDefault()

    if (
      editPayload.Mobile.length > 0 &&
      !isAustralianNumber(editPayload.Mobile)
    ) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    if (editPayload.IssueID && editPayload.IssueID.length === 0) {
      toast.error("Please select a servivce")
      return
    }

    setEditLoader(true)

    apiUpdateSupportWorkers({
      SWorkerID: editPayload.SWorkerID,
      Mobile: convertAustralianNumber(editPayload.Mobile),
      Name: editPayload.Name,
      Email: editPayload.Email,
      Address: editPayload.Address,
      Location: editPayload.Location,
      IssueID: editPayload.IssueID,
      IsAcceptRef: editPayload.IsAcceptRef,
      Description: editPayload.Description
    }).then((response) => {
      apiGetSupportServices().then((response) => {
        const updatedList = getList(response)
        setList(updatedList)
        // setList(response)
        toast.success("Support service updated")
        setEditLoader(false)
      })
      handleCloseEditModal()
    })
  }

  const [showEditModal, setShowEditModal] = React.useState(false)

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  const onChangeEditPaylod = (e) => {
    setEditPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onChangeDescription = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/);
    const wordCount = words.length;
  
    if (wordCount > 100) {
      const truncatedInput = words.slice(0, 100).join(' ');
      setEditPayload((prevState) => ({
        ...prevState,
        Description: truncatedInput,
      }));
    } else {
      setEditPayload((prevState) => ({
        ...prevState,
        Description:inputValue
      }))
    }
  };

  const deleteSW = (id) => {
    setDeleteLoader(true)
    apiDeleteSupportWorkers(id).then((response) => {
      toast.success("Service deleted successfully")
      apiGetSupportServices().then((response) => {
        const updatedList = getList(response)
        setList(updatedList)
        // setList(response)
        setDeleteLoader(false);
        showDeleteConfirmation(false);
      })
    })
  }

  const updateEditPayload = (e) => {
    let IssueIDs = editPayload.IssueID || []

    if (IssueIDs && !IssueIDs.includes(e.target.value)) {
      setEditPayload((prevState) => ({
        ...prevState,
        IssueID: [...IssueIDs, e.target.value]
      }))
    } else {
      setEditPayload((prevState) => ({
        ...prevState,
        IssueID: IssueIDs.filter((i) => i !== e.target.value)
      }))
    }
  }

  const changeAcceptRef = (newRef) => {
    setEditPayload((prevState) => ({
      ...prevState,
      IsAcceptRef: newRef
    }))
    setAcceptRef(newRef)
  }

  const deleteConfirmationModal = () => {
    return (
      <Modal 
        show={deleteConfirmationModel} 
        onHide={() => showDeleteConfirmation(false)} 
        backdrop="static" 
        className="verification-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title>Are you sure to delete this Service ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              deleteConfirmationModel && deleteConfirmationModel.Name
            }
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className='input-element'>
            <button onClick={() => deleteSW(deleteConfirmationModel)}
              className='btn btn-edit'
              disabled={deleteLoader}
            >
              {deleteLoader ? <Spinner animation='border' size='sm' /> : 'Yes'}
            </button>
            <div className="spacer-sm"></div>
            <button onClick={() => showDeleteConfirmation(false)}
              className='btn btn-delete'>
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      {deleteConfirmationModal()}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        className="edit-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div className="top-icon">
              <PencilIcon />
            </div>

            <h4>Edit Support Service</h4>
          </div>

          <div className="spacer-sm"></div>

          <form onSubmit={submitEdit}>
            <div className="input-element">
              <div className="input-normal">
                <input
                  autoFocus
                  value={editPayload.Name}
                  name="Name"
                  type="text"
                  placeholder="Service Name"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <input
                  autoFocus
                  value={editPayload.Email}
                  name="Email"
                  disabled
                  type="email"
                  placeholder="Email"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <input
                  autoFocus
                  value={editPayload.Mobile}
                  name="Mobile"
                  type="text"
                  placeholder="Mobile"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            {/* <div className="input-element">
              <div className="input-normal">
                <input
                  autoFocus
                  value={editPayload.Address}
                  name="Address"
                  type="text"
                  placeholder="Address"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div> */}

            <div className="input-element">
              <div className="input-normal">
                <Autocomplete
                  options={mapOptions}
                  required
                  defaultValue={editPayload.Location}
                  apiKey={apiKeyShan}
                  onPlaceSelected={(place) => onPlaceSelected(place)}
                />
              </div>
            </div>
            <div className="">
              {services &&
                services.map((service) => (
                  <>
                    <div className="service-item pb-2">
                      <Badge bg="light">
                        <input
                          onChange={updateEditPayload}
                          type="checkbox"
                          value={service.IssueID}
                          id={service.IssueID}
                          checked={
                            (editPayload?.IssueID &&
                              editPayload.IssueID.includes(service.IssueID)) ||
                            false
                          }
                        />
                        <label for={service.IssueID}>{service.Title}</label>
                      </Badge>
                    </div>
                  </>
                ))}
            </div>

            <div className="input-element">
              <div className="input-normal">
                <label>Accepting Warm Referrals</label>
                <button
                  type="button"
                  class={isAcceptRef ? "btn btn-primary me-2" : "btn btn-outline-primary me-2"}
                  onClick={() => changeAcceptRef(true)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  class={!isAcceptRef ? "btn btn-danger me-2" : "btn btn-outline-danger me-2"}
                  onClick={() => changeAcceptRef(false)}
                >
                  No
                </button>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <textarea 
                  className="form-control" 
                  autoFocus
                  value={editPayload.Description}
                  name="Description"
                  rows="3"
                  placeholder="Description"
                  onChange={onChangeDescription}
                ></textarea>
              </div>
            </div>

            <div className="input-element text-center">
              <button
                type="submit"
                className="primary-button primary-button-small no-animation"
              >
                <div className="button-text">
                  {editLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update"
                  )}
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/ia/issues")}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className="text-left">Support services</h2>
              </div>
              <div className="input-element text-center">
                <button
                  onClick={() => routeTo("/ia/add-support-service")}
                  type="submit"
                  className="primary-button button-add"
                >
                  <div className="button-text"></div>
                  <div className="right-icon">
                    <PlusIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>
              <div className="accordian-list">
                <div className="d-flex sub-title-with-icon align-items-center">
                  <div className="icon-left">
                    <FlagIcon />
                  </div>
                  <h3>
                    Support services{" "}
                    {loading ? <Spinner animation="border" size="sm" /> : ""}
                  </h3>
                </div>
                <div className="spacer-sm"></div>
                <div className="">
                  <div>
                    <div className="search-bar">
                      <div className="search-input">
                        <input
                          value={payload ? payload : ""}
                          onChange={filter}
                          type="text"
                          placeholder="Search support service"
                        ></input>
                        <div className="right-icon" onClick={clearFilters}>
                          {payload && payload.length > 0 ? (
                            <XMarkIcon />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </div>
                      </div>
                    </div>

                    {list &&
                      list.map((item, i) => (
                        <Disclosure
                          as="div"
                          className={
                            sIndex === i ? "acc-wrapper open" : "acc-wrapper"
                          }
                          key={item.id}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className="d-flex acc-wrapper-button"
                                onClick={() => setSIndex(i)}
                              >
                                <div>
                                  <span className="text-orange">
                                    {item.Name}
                                  </span>
                                </div>
                                <ChevronUpIcon
                                  className={`${open ? "" : "rotate-180"}`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="acc-panel">
                                <p>
                                  <span className="text-orange">
                                    Created Date
                                  </span>
                                  <br />
                                  <span>
                                    {moment(item.CreatedDate).format(
                                      "YYYY-MMM-DD"
                                    )}
                                  </span>
                                </p>
                                <p>
                                  <span className="text-orange">Mobile</span>
                                  <br />
                                  <span>{item.Mobile}</span>
                                </p>

                                <p>
                                  <span className="text-orange">Email</span>
                                  <br />
                                  <span>{item.Email} </span>
                                </p>
                                <p>
                                  <span className="text-orange">Address</span>
                                  <br />
                                  <span>{item.Location}</span>
                                </p>

                                {/* <p>
                                  <span className="text-orange">Address</span>
                                  <br />
                                  <span>{item.Address}</span>
                                </p> */}

                                <p>
                                  <span className="text-orange">
                                    Services Offered
                                  </span>
                                  <br />

                                  <span>
                                    {item.IssueID &&
                                      mapServices(item.IssueID).map((s) => (
                                        <>
                                          {s.Title} <br />
                                        </>
                                      ))}
                                  </span>
                                </p>

                                <p>
                                  <span className="text-orange">Accept Referrals</span>
                                  <br />
                                  <span>{item.IsAcceptRef ? "Yes" : "No"} </span>
                                </p>

                                <p>
                                  <span className="text-orange">Description</span>
                                  <br />
                                  <span>{item.Description}</span>
                                </p>

                                <div className="row justify-content-center">
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <div>
                                      <Button
                                        className="btn-edit"
                                        onClick={() => {
                                          setShowEditModal(true)
                                          setEditPayload((prevState) => ({
                                            ...item,
                                            IssueID: JSON.parse(item.IssueID).length > 0 ? [
                                              ...JSON.parse(item.IssueID)
                                            ] : null
                                          }))
                                          setAcceptRef(item.IsAcceptRef)
                                        }}
                                      >
                                        <span>
                                          <PencilIcon />
                                        </span>
                                        Edit
                                      </Button>
                                      <Button
                                        className="btn-delete"
                                        // onClick={() => deleteSW(item.SWorkerID)}
                                        onClick={() =>
                                          showDeleteConfirmation(item.SWorkerID)
                                        }
                                      >
                                        <span>
                                          <TrashIcon />
                                        </span>{" "}
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default SupportServiceList
