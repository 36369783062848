import React from "react"

import { Button, Modal, Spinner } from "react-bootstrap"
import Autocomplete from "react-google-autocomplete"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import {
  ArrowLongLeftIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon
} from "@heroicons/react/24/solid"

import { apiDeletePMA, apiGetAllPMA, apiUpdatePMA } from "../../../core/api"
import { apiKeyShan, mapOptions } from "../../../core/plugins/config"
import {
  convertAustralianNumber,
  isAustralianNumber
} from "../../../core/plugins/validator"
function AgencyAdminList() {
  const navigation = useNavigate()
  const [sIndex, setSIndex] = React.useState(false)
  const routeTo = (route) => {
    navigation(route)
  }
  const [payload, setPayload] = React.useState(false)
  const [deleteConfirmationModel,showDeleteConfirmation] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  
  const filter = (e) => {
    setPayload(e.target.value)
    let filtered = list.filter((item) =>
      item.CompanyName.includes(e.target.value)
    )
    setList(filtered)

    if (e.target.value.length === 0) {
      clearFilters()
      setPayload(false)
      setList(defaultList)
    }
  }

  const onPlaceSelected = (place) => {
    if (place && place?.formatted_address) {
      setEditPayload((prevState) => ({
        ...prevState,
        Address: place?.formatted_address
      }))
    }
  }

  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setLoading(true)
    apiGetAllPMA().then((response) => {
      setList(response)
    
      setLoading(false)
    })
  }, [])

  const [list, setList] = React.useState(false)
  const [defaultList, setDefaultList] = React.useState(false)
  const clearFilters = () => {
    setPayload(false)
    apiGetAllPMA().then((response) => {
      setList(response)
      setDefaultList(response)
    })
  }
  const [showEditModal, setShowEditModal] = React.useState(false)

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }
  const [editPayload, setEditPayload] = React.useState({
    CompanyName: "",
    AgencyAdminName: "",
    Email: "",
    Mobile: "",
    Address: "",
    PMAID: "",
  })
  const [editLoader, setEditLoader] = React.useState(false)

  const submitEdit = (e) => {
    e.preventDefault()
    if (
      editPayload.Mobile.length > 0 &&
      !isAustralianNumber(editPayload.Mobile)
    ) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    setEditLoader(true)
    apiUpdatePMA(editPayload)
      .then((response) => {
        toast.success("Property Manager Updated")
        apiGetAllPMA().then((response) => {
          setList(response)
          setEditLoader(false)
        })
        handleCloseEditModal()
      })
      .finally(() => {
        setEditLoader(false)
      })
  }

  const deletePMA = (id) => {
    setDeleteLoader(true)
    apiDeletePMA(id).then((response) => {
      if (response) {
        toast.success("Agency Admin Deleted Successfully ")
      }
      apiGetAllPMA().then((response) => {
        setList(response)
      })
      setDeleteLoader(false);
      showDeleteConfirmation(false);
    })
  }

  const onChangeEditPaylod = (e) => {
    setEditPayload((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === "Mobile"
          ? convertAustralianNumber(e.target.value)
          : e.target.value
    }))
  }

  const deleteConfirmationModal = () => {
    return (
      <Modal 
        show={deleteConfirmationModel} 
        onHide={() => showDeleteConfirmation(false)} 
        backdrop="static" 
        className="verification-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title>Are you sure to delete this Agency Admin ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              deleteConfirmationModel && deleteConfirmationModel.Name
            }
          </p>
        </Modal.Body>

        <Modal.Footer>
          <div className='input-element'>
            <button onClick={() => deletePMA(deleteConfirmationModel)}
              className='btn btn-edit'
              disabled={deleteLoader}
            >
              {deleteLoader ? <Spinner animation='border' size='sm' /> : 'Yes'}
            </button>
            <div className="spacer-sm"></div>
            <button onClick={() => showDeleteConfirmation(false)}
              className='btn btn-delete'>
              No
            </button>
          </div>

        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      {deleteConfirmationModal()}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        className="edit-modal"
      >
        <Modal.Body>
          <h4>Edit Agency</h4>
          <div className="spacer-sm"></div>
          <form onSubmit={submitEdit}>
            <div className="input-element">
              <div className="input-normal">
                <input
                  value={editPayload.CompanyName}
                  name="CompanyName"
                  type="text"
                  placeholder="Agency Name"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <input
                  value={editPayload.AgencyAdminName}
                  name="AgencyAdminName"
                  type="text"
                  placeholder="Agency Admin Name"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <input
                  value={editPayload.Email}
                  name="Email"
                  disabled
                  type="email"
                  placeholder="Email"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <input
                  value={editPayload.Mobile}
                  name="Mobile"
                  type="text"
                  placeholder="Phone Number"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-normal">
                <Autocomplete
                  options={mapOptions}
                  defaultValue={editPayload.Address}
                  required
                  apiKey={apiKeyShan}
                  onPlaceSelected={(place) => onPlaceSelected(place)}
                />
              </div>
            </div>

            <div className="input-element text-center">
              <button
                type="submit"
                className="primary-button primary-button-small no-animation"
              >
                <div className="button-text">
                  {editLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update"
                  )}
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/ia/issues")}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className="text-left">Agency Admins</h2>
              </div>

              <div className="input-element text-center">
                <button
                  onClick={() => routeTo("/ia/add-agency-admin")}
                  type="submit"
                  className="primary-button button-add no-animation"
                  style={{ marbinBottom: 0 }}
                >
                  <div className="button-text"></div>
                  <div className="right-icon">
                    <PlusIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>
              <div className="accordian-list">
                <div className="d-flex sub-title-with-icon align-items-center">
                  <div className="icon-left">
                    <UserIcon />
                  </div>
                  <h3>
                    Agency Admins &nbsp;
                    {loading ? <Spinner animation="border" size="sm" /> : ""}
                  </h3>
                </div>
                <div className="spacer-sm"></div>
                <div className="">
                  <div>
                    <div className="search-bar">
                      <div className="search-input">
                        <input
                          value={payload ? payload : ""}
                          onChange={filter}
                          type="text"
                          placeholder="Search an agency"
                        ></input>
                        <div className="right-icon" onClick={clearFilters}>
                          {payload && payload.length > 0 ? (
                            <XMarkIcon />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </div>
                      </div>
                    </div>

                    {list &&
                      list.map((item, i) => (
                        <Disclosure
                          as="div"
                          className={
                            sIndex === i ? "acc-wrapper open" : "acc-wrapper"
                          }
                          key={item.PMAID}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className="d-flex acc-wrapper-button"
                                onClick={() => {
                                  setSIndex(i)
                                }}
                              >
                                <div>
                                  <span className="text-orange">
                                    {item.CompanyName}
                                  </span>{" "}
                                </div>
                                <ChevronUpIcon
                                  className={`${open ? "" : "rotate-180"}`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="acc-panel">
                                <p>
                                  <span className="text-orange">
                                    Admin Name
                                  </span>
                                  <br />
                                  <span>{item.AgencyAdminName}</span>
                                </p>
                                <p>
                                  <span className="text-orange">Email</span>
                                  <br />
                                  <span>{item.Email}</span>
                                </p>

                                <p>
                                  <span className="text-orange">Mobile</span>
                                  <br />
                                  <span>{item.Mobile}</span>
                                </p>

                                <p>
                                  <span className="text-orange">Address</span>
                                  <br />
                                  <span>{item.Address} </span>
                                </p>

                                <div className="row justify-content-center">
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <div>
                                      <Button
                                        className="btn-edit"
                                        onClick={() => {
                                          setShowEditModal(true)
                                          setEditPayload(item)
                                        }}
                                      >
                                        <span>
                                          <PencilIcon />
                                        </span>
                                        Edit
                                      </Button>
                                      <Button
                                        className="btn-delete"
                                        // onClick={() => deletePMA(item.PMAID)}
                                        onClick={() =>
                                          showDeleteConfirmation(item.PMAID)
                                        }
                                      >
                                        <span>
                                          <TrashIcon />
                                        </span>{" "}
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}

                    {list && list.length === 0 && (
                      <div className="empty-list-data">
                        <div className="spacer-sm"></div>
                        <h4>No agency admins found</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default AgencyAdminList
