import "./core/styles/app.scss"
import "react-toastify/dist/ReactToastify.css"

import React from "react"

import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import AppLanding from "./components/pages"
import AALanding from "./components/pages/AA"
import AddPropertyManager from "./components/pages/AA/add-pm"
import PropertyManagers from "./components/pages/AA/issues"
import ListPropertyManagers from "./components/pages/AA/list-pm"
import ForgotPassword from "./components/pages/forgot"
import IALanding from "./components/pages/IA"
import AddAgencyAdmin from "./components/pages/IA/add-agency-admin"
import AddSupportService from "./components/pages/IA/add-support-service"
import AgencySupportList from "./components/pages/IA/issues"
import AgencyAdminList from "./components/pages/IA/list-aa"
import SupportServiceList from "./components/pages/IA/list-support-service"
import Logout from "./components/pages/logout"
import PMLanding from "./components/pages/PM"
import PMDashboard from "./components/pages/PM/dashboard"
import PMRaiseIssue from "./components/pages/PM/raise-issue"
import { AWSCognito, getUser, signOut } from "./core/plugins/Entry"
import { ROUTES } from "./core/routes"
import Layout from "./components/layouts/Layout"
import { useIdleTimer } from "react-idle-timer"
import { PrivacyPage } from "./components/pages/privacy"
import { TermsPage } from "./components/pages/terms-and-conditions"

function App() {
  const [Auth, setAuth] = React.useState(null)

  React.useEffect(() => {
    AWSCognito()
    setAuth(getUser())
  }, [])

  const onIdle = async () => {
    await signOut()
    setAuth(null)
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: onIdle
  })

  return (
    <article className="App">
      <Layout>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        theme="colored"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<Logout />}></Route>
          <Route path={ROUTES.INDEX} element={<AppLanding />}></Route>
          <Route path={ROUTES.FORGOT} element={<ForgotPassword />}></Route>

          <Route path={ROUTES.PM_LOGIN} element={<PMLanding />}></Route>
          <Route path={ROUTES.IA_LOGIN} element={<IALanding />}></Route>
          <Route path={ROUTES.AA_LOGIN} element={<AALanding />}></Route>

          <Route path={ROUTES.PM_DASHBOARD} element={<PMDashboard />}></Route>
          <Route
            path={ROUTES.PM_RAISE_ISSUE}
            element={<PMRaiseIssue />}
          ></Route>

          <Route path="/aa/pm-list" element={<ListPropertyManagers />}></Route>
          <Route path="/aa/issues" element={<PropertyManagers />}></Route>

          <Route path="/aa/add-pm" element={<AddPropertyManager />}></Route>
          <Route path="/logout" element={<Logout />}></Route>

          <Route path="/ia/issues" element={<AgencySupportList />}></Route>
          <Route
            path="/ia/add-support-service"
            element={<AddSupportService />}
          ></Route>
          <Route
            path="/ia/support-service"
            element={<SupportServiceList />}
          ></Route>
          <Route
            path="/ia/agency-admin-list"
            element={<AgencyAdminList />}
          ></Route>
          <Route
            path="/ia/add-agency-admin"
            element={<AddAgencyAdmin />}
          ></Route>

<Route
            path="/privacy-policy"
            element={<PrivacyPage />}
          ></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsPage />}
          ></Route>
          <Route path="*">
            <>Page Not found</>
          </Route>
        </Routes>
      </BrowserRouter>
      </Layout>
    </article>
  )
}

export default App
