export const ROUTES = {
  INDEX: "/",
  FORGOT: "/forgot-password",
  PRIVACY: "/privacy",
  TERMS: "/terms",

  PM_DASHBOARD: "/PM/dashbaord",
  PM_RAISE_ISSUE: "/PM/raise-issues",
  PM_LOGIN: "/PM/login",

  IA_DASHBOARD: "/IA/dashboard",
  IA_LOGIN: "/IA/login",
  IA_ALL_AGENCIES: "/IA/all-agencies",
  IA_ALL_SUPPORT_SERVICES: "/IA/all-support-serivces",
  IA_ADD_AGENCY: "/IA/add-agency",
  IA_ADD_SUPPORT_SERVICE: "/IA/add-support-servivce",

  AA_DASHBOARD: "/AA/dashboard",
  AA_LOGIN: "/AA/login",
  AA_ALL_PMS: "/AA/all-property-admins",
  AA_ADD_PM: "/AA/add-property-admin"
}
