import React from "react"

import { useNavigate } from "react-router-dom"

import { ArrowLongRightIcon, UserIcon } from "@heroicons/react/24/solid"

import { AWSCognito } from "../../core/plugins/Entry"
import { ROUTES } from "../../core/routes"
function AppLanding() {
  const navigate = useNavigate()

  const routeTo = (url) => {
    navigate(url)
  }
  AWSCognito()
  return (
    <div className="container-fluid pm-wrapper">
      <div className="pm-logo-container">
        <div className="pm-child_1">
          <div className="pm-child_2">
            <div className="pm-text-container">
              <h2 className="text">PM Assist</h2>
              <p>by the</p>
              <img src="/images/logo.jpg" alt="PM Assist Logo" />
            </div>
          </div>
        </div>
      </div>

      <div className="intro-text">
        <h3>Welcome</h3>
        <h5>Efficiency Unleashed, Simplify Property Management</h5>
      </div>

      <div className="main-user-buttons">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div
                    className="user-button"
                    onClick={() => routeTo(ROUTES.PM_LOGIN)}
                  >
                    <div className="d-flex align-items-end">
                      <div className="icon-left">
                        <UserIcon />
                      </div>
                      <div className="user-button-text">
                        <span>I'm a</span> <br />
                        Property Manager
                      </div>
                    </div>
                    <div className="icon-right">
                      <ArrowLongRightIcon />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div
                    className="user-button"
                    onClick={() => routeTo(ROUTES.AA_LOGIN)}
                  >
                    <div className="d-flex align-items-end">
                      <div className="icon-left">
                        <UserIcon />
                      </div>
                      <div className="user-button-text">
                        <span>I'm an</span> <br />
                        Agency Admin
                      </div>
                    </div>
                    <div className="icon-right">
                      <ArrowLongRightIcon className="h-6 w-6 text-blue-500" />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div
                    className="user-button"
                    onClick={() => routeTo(ROUTES.IA_LOGIN)}
                  >
                    <div className="d-flex align-items-end">
                      <div className="icon-left">
                        <UserIcon />
                      </div>
                      <div className="user-button-text">
                        <span>I'm an</span> <br />
                        Incommunity Admin
                      </div>
                    </div>
                    <div className="icon-right">
                      <ArrowLongRightIcon className="h-6 w-6 text-blue-500" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppLanding
