import React from 'react';

import { ROUTES } from '../../core/routes';
const AppFooter = () => {
  return (
    <>
      <div className="d-block justify-content-center text-center forgot-password-link-wrapper">
        <a href={ROUTES.FORGOT}>Forgot Password ?</a>
      </div>
      <p>&nbsp;</p>
      <p className="small text-center">PM Assist by</p>
    
      <p className="small text-orange text-center">Tenancy Skills Institute</p>
    </>
  )
}

export default AppFooter
