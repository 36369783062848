import axios from 'axios';
const apiKey = "5rwBoRd5Dk9jLnn8r9SLOaYtsKRr2jMJ1IchTt5j";
// const apiKey = "qIlD2ZNPv99z7leN3iU7daM1cEFEvB3K8SmS7NkI";
const httpClientAxios = axios.create({
  baseURL: "https://c0d6lr50o4.execute-api.ap-southeast-2.amazonaws.com/Staging", //move to env
  // baseURL: "https://ysp0zcll4l.execute-api.us-east-1.amazonaws.com/Dev",
  headers: {
    "x-api-key": apiKey,
    "Content-Type": "application/json", // Adjust content type if necessary
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*"
  }
})

const httpClient = axios.create({
  baseURL: "https://pm-db-server-a621056b9f6d.herokuapp.com",
  headers: {
    "Content-Type": "application/json" // Adjust content type if necessary
  }
})

export { httpClient, httpClientAxios };
