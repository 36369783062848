import React, { useState } from 'react';

import {
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ArrowRightIcon,
  InformationCircleIcon,
  LockClosedIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { EyeSlashIcon } from '@heroicons/react/24/solid';

import AppFooter from '../../../components/shared/footer';
import { apiGetPM } from '../../../core/api';
import {
  signIn,
  verifyUserCode,
} from '../../../core/plugins/Entry';
import { ROUTES } from '../../../core/routes';
import AuthLayout from '../../layouts/AuthLayout';
function PMLanding() {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const [payload, setPayload] = React.useState({
    username: "",
    password: "",
    type: "PM"
  })

  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onConfirmPayloadChange = (e) => {
    setConformPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [confirm, setConformPayload] = React.useState({
    email: "",
    code: ""
  })

  const [showConfirmUser, setShowConfirmUser] = React.useState(false)

  const openConfirmUserModal = () => {
    setShowConfirmUser(true)
  }

  const handleConfirmUserModalClose = () => {
    setShowConfirmUser(false)
  }

  const submitConfirmUser = () => {
    handleConfirmUserModalClose()
  }

  const [agreed, setAgreed] = React.useState(
    localStorage.getItem('userAgreedToTerms') === 'true'
  )

  const handleAgreementChange = (e) => {
    const isAgreed = e.target.checked;
    setAgreed(isAgreed);
    localStorage.setItem('userAgreedToTerms', isAgreed);
  }

  const [tempUser, setTempUser] = useState(null)
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value)
  }

  const submitVerificationCode = async () => {
    try {
      const verified = await verifyUserCode(tempUser, verificationCode)
      if (verified) {
        apiGetPM(tempUser.UUID).then((response) => {
          if (response && response.PMID) {
            toast.success(`Welcome`)
            navigate(ROUTES.PM_DASHBOARD)
          } else {
            toast.warn("User is not a Property Manager")
          }
        })
        setShowVerificationModal(false)
      } else {
        toast.error("Invalid verification code")
      }
    } catch (error) {
      toast.error("Error verifying code")
    }
  }

  const login = (e) => {
    e.preventDefault()
    if (!agreed) {
      toast.warn("Please agree to the Privacy and Terms & Conditions");
      return;
    }
    setLoading(true)
    setTimeout(() => {
      signIn(payload)
        .then((response) => {
          if (response.user.message === "User is not confirmed.") {
            openConfirmUserModal()
            setLoading(false)
          }
          if (response.requireVerification && response.user.username) {
            setTempUser(response.tempUser)
            setShowVerificationModal(true)
            setLoading(false)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }, 1000)
  }
  return (
    <>
      <Modal
        show={showConfirmUser}
        onHide={handleConfirmUserModalClose}
        backdrop="static"
        className="confirm-modal"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <InformationCircleIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>User not confirmed.</h4>
          <p>
            Verify your email address <br />
            <small>
              Please click the verification link sent to your email address
            </small>
          </p>

          <div className="input-element text-center">
            <button
              onClick={submitConfirmUser}
              className="primary-button-small"
            >
              <div className="button-text">Close</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)} backdrop="static" className="verification-modal">
        <Modal.Header closeButton>
          <Modal.Title>Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the verification code sent to your email.</p>
          <div className="verification-input">
            <input
              type="text"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              placeholder="Enter code here"
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={submitVerificationCode} className="btn btn-primary">
            Verify
          </button>
        </Modal.Footer>
      </Modal>
      <AuthLayout>
        <div className="container login-form-page ">
          <div className="d-flex flex-column justify-content-center">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="spacer-lg"></div>
                <div className="spacer-lg"></div>
                <h2 className="text-center">Login as a Property Manager</h2>
                <h4>Please sign in to continue</h4>
                <div className="spacer-lg"></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <form onSubmit={login}>
                  <div className="input-element">
                    {/* <label>Username</label> */}
                    <div className="input-wrapper">
                      <div className="left-icon">
                        <UserIcon />
                      </div>
                      <input
                        autoFocus
                        name="username"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={onChange}
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    {/* <label>Password</label> */}
                    <div className="input-wrapper">
                      <div className="left-icon">
                        <LockClosedIcon />
                      </div>
                      <input
                        name="password"
                        type="password"
                        placeholder="Password"
                        required
                        onChange={onChange}
                      ></input>
                      <div className="right-icon">
                        <EyeSlashIcon />
                      </div>
                    </div>
                  </div>

                  <div className="input-element text-center">
                    <label>
                      <input
                        type="checkbox"
                        checked={agreed}
                        onChange={handleAgreementChange}
                      />
                      <div className="privacy-terms-link-wrapper">
                        I agree to the <a href='/privacy-policy'>Privacy</a> and <a href='/terms-and-conditions'>Terms & Conditions</a>
                      </div>
                    </label>
                  </div>

                  <div className="input-element text-center">
                    <button
                      disabled={loading}
                      type="submit"
                      className="primary-button"
                    >
                      <div className="button-text">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Login"
                        )}
                      </div>
                      <div className="right-icon">
                        <ArrowRightIcon />
                      </div>
                    </button>
                  </div>
                </form>
                <div className="spacer-lg"></div>
                <AppFooter />
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  )
}

export default PMLanding
