import React, { useState } from 'react';

import {
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';

import {
  ArrowRightIcon,
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import AppFooter from '../../../components/shared/footer';
import { apiGetIA } from '../../../core/api';
import {
  changePasswordImmediately,
  signIn,
  verifyUserCode,
} from '../../../core/plugins/Entry';
import AuthLayout from '../../layouts/AuthLayout';
function PMLanding() {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const [payload, setPayload] = React.useState({
    username: "",
    password: "",
    type: "IA"
  })

  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [tempUser, setTempUser] = useState(null)
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value)
  }

  const submitVerificationCode = async () => {
    try {
      const verified = await verifyUserCode(tempUser, verificationCode)
      if (verified) {
        apiGetIA(tempUser.UUID).then((response) => {
          if (response && response.message) {
            toast.success(`Welcome`)
            navigate("/ia/issues")
            setLoading(false)
          } else {
            toast.warn("User is not a valid InCommunity Admin")
          }
        })
        setShowVerificationModal(false)
      } else {
        toast.error("Invalid verification code")
      }
    } catch (error) {
      toast.error("Error verifying code")
    }
  }

  const login = (e) => {
    e.preventDefault()
    setLoading(true)
    setTimeout(() => {
      signIn(payload).then((response) => {
        if (response.user && response.user.username) {
          if(response.challengeName === "NEW_PASSWORD_REQUIRED"){
            setShowChangePassword(true);

            setChangePayload({
              oldPassword:payload.password,
              newPassword:"",
              user:response,
              username:payload.username,
            })
           
            return;
          } else {
            if (response.requireVerification) {
              setTempUser(response.tempUser)
              setShowVerificationModal(true)
              setLoading(false)
            }
          }
          console.log(response)
        }
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
    }, 1000)
  }

  const [showChangePassword, setShowChangePassword] = React.useState(false);
const [changePayload, setChangePayload] = React.useState({
  oldPassword:"",
  newPassword:"",
  user:"",
  confirmPassword:"",
  username:""
})
  const handleChangePasswordModalClose = () =>{
    setShowChangePassword(false);
  }

  const formSubmitChangePassword =   (e) =>{
    e.preventDefault();

    if(!validator.isStrongPassword(changePayload.newPassword,{ minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })){
      toast.error("Password must contain 8 characters with minimum 1 uppercase, 1 lowercase , 1 number and 1 symbol  ")
      return;
    }

    if(changePayload.newPassword === changePayload.confirmPassword){
      changePasswordImmediately(changePayload)
      .then(response=>{
        if(response){
          navigate("/ia/issues")
        }
      })

      return;
    }

    toast.error("Passwords doesn't match");

   
  }

  const onChangePayload = (e) =>{

    setChangePayload(prevState=>({
      ...prevState,
      [e.target.name]:e.target.value
    }))

  }

  return (
    <>
      <Modal
        show={showChangePassword}
        onHide={handleChangePasswordModalClose}
        backdrop="static"
        className="confirm-modal"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <EnvelopeIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Change Password</h4>
          <p>
            Please change your temporary password <br />
          </p>

          <form onSubmit={formSubmitChangePassword}>
           
           

            <div className="input-element">
              <div className="input-wrapper">
                <input
                  style={{
                    paddingLeft: 0
                  }}
                  autoFocus
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  required
         
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-wrapper">
                <input
                  style={{
                    paddingLeft: 0
                  }}
                  autoFocus
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  required
               
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className="input-element text-center">
              <button type="submit" className="primary-button-small">
                <div className="button-text">Change Password</div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)} backdrop="static" className="verification-modal">
        <Modal.Header closeButton>
          <Modal.Title>Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the verification code sent to your email.</p>
          <div className="verification-input">
            <input
              type="text"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              placeholder="Enter code here"
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={submitVerificationCode} className="btn btn-primary">
            Verify
          </button>
        </Modal.Footer>
      </Modal>
      <AuthLayout>
        <div className="container login-form-page ">
          <div className="d-flex flex-column justify-content-center">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="spacer-lg"></div>
                <div className="spacer-lg"></div>
                <h2 className="text-center">Login as an InCommunity Admin</h2>
                <h4>Please sign in to continue</h4>
                <div className="spacer-lg"></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <form onSubmit={login}>
                  <div className="input-element">
                    {/* <label>Username</label> */}
                    <div className="input-wrapper">
                      <div className="left-icon">
                        <UserIcon />
                      </div>
                      <input
                        autoFocus
                        name="username"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={onChange}
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    {/* <label>Password</label> */}
                    <div className="input-wrapper">
                      <div className="left-icon">
                        <LockClosedIcon />
                      </div>
                      <input
                        name="password"
                        type="password"
                        placeholder="Password"
                        required
                        onChange={onChange}
                      ></input>
                    </div>
                  </div>

                  <div className="input-element text-center">
                    <button
                      disabled={loading}
                      type="submit"
                      className="primary-button"
                    >
                      <div className="button-text">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Login"
                        )}
                      </div>
                      <div className="right-icon">
                        <ArrowRightIcon />
                      </div>
                    </button>
                  </div>
                </form>
                <div className="spacer-lg"></div>
               <AppFooter/>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  )
}

export default PMLanding
