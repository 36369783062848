import React from "react"

import { Badge, Spinner } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Autocomplete from "react-google-autocomplete"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid"

import { apiCreateSupportWorkers, apiGetAllIssues } from "../../../core/api"
import { apiKeyShan, mapOptions } from "../../../core/plugins/config"
import { isAustralianNumber, convertAustralianNumber  } from "../../../core/plugins/validator"
function AddSupportService() {
  const [value, setValue] = React.useState(false)

  const [step, setStep] = React.useState(0)

  const clearValue = () => {
    setValue(false)
    setStep(0)
  }

  const navigation = useNavigate()

  const routeTo = (url) => {
    navigation(url)
  }

  const [valueOk, setOk] = React.useState("ok")

  const switchOk = (v) => {
    setOk(v)
  }

  const [show, setModal] = React.useState(false)
  const handleClose = () => {
    setModal(false)
  }

  const handleShow = (e) => {
    e.preventDefault()

    setModal(true)
  }

  const [services, setServices] = React.useState(false)

  const [payload, setPayload] = React.useState({
    Mobile: "",
    Name: "",
    Email: "",
    Address: "",
    Location: "",
    IssueID: [],
    IsAcceptRef: false,
    Description: ""
  })

  const [isAcceptRef, setAcceptRef] = React.useState(false)

  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onChangeDescription = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/);
    const wordCount = words.length;
  
    if (wordCount > 100) {
      const truncatedInput = words.slice(0, 100).join(' ');
      setPayload((prevState) => ({
        ...prevState,
        Description: truncatedInput,
      }));
    } else {
      setPayload((prevState) => ({
        ...prevState,
        Description: inputValue,
      }));
    }
  };

  let s_services = []
  const pushServices = (e) => {
    if (!s_services.includes(e.target.value)) {
      s_services.push(e.target.value)
    }

    if (!payload.IssueID.includes(e.target.value)) {
      setPayload((prevState) => ({
        ...prevState,
        IssueID: [...payload.IssueID, e.target.value]
      }))
    } else {
      setPayload((prevState) => ({
        ...prevState,
        IssueID: payload.IssueID.filter((i) => i !== e.target.value)
      }))
    }
  }

  const [progress, setProgress] = React.useState(false)
  const createService = (e) => {
    e.preventDefault()

    if (payload.Mobile.length > 0 && !isAustralianNumber(payload.Mobile)) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    if (payload.IssueID && payload.IssueID.length === 0) {
      toast.error("Please select a servivce")
      return
    }

    payload.IsAcceptRef = isAcceptRef
    payload.Mobile = convertAustralianNumber(payload.Mobile)

    setProgress(true)
    apiCreateSupportWorkers(payload)
      .then((response) => {
        toast.success("Support worker created")
        routeTo("/ia/support-service")
        setProgress(false)
      })
      .finally(() => {
        setProgress(false)
      })
  }

  const [user_location, setUserLocation] = React.useState(false)

  const onPlaceSelected = (place) => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address)

      setPayload((prevState) => ({
        ...prevState,
        Location: place?.formatted_address
      }))
    }
  }
  React.useEffect(() => {
    apiGetAllIssues().then((response) => {
      if (response) {
        setServices(response)
      }
    })
  }, [])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-small"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <CheckCircleIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Done</h4>
          <p>
            Support service <br />
            Added successfully
          </p>
          <div className="spacer-lg"></div>
          <div className="input-element text-center">
            <button
              type="submit"
              className="primary-button-small"
              onClick={() => {
                handleClose()
                routeTo("/ia/support-service")
              }}
            >
              <div className="button-text">Ok</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 position-relative">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/ia/support-service")}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className="text-left">Add Support Service</h2>
              </div>

              {value && value !== "no" && (
                <div className="ticket-item">
                  <p>{value} assigned</p>
                  <div onClick={clearValue}>
                    <XMarkIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>

              <>
                <form onSubmit={createService}>
                  <div className="input-element">
                    <div className="input-normal">
                      <label>Support service name</label>
                      <input
                        autoFocus
                        name="Name"
                        required
                        onChange={onChange}
                        type="text"
                        placeholder="Support service name"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Phone number</label>
                      <input
                        name="Mobile"
                        required
                        onChange={onChange}
                        type="text"
                        placeholder="Phone number"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Email</label>
                      <input
                        name="Email"
                        type="email"
                        required
                        onChange={onChange}
                        placeholder="Email"
                      ></input>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Address</label>

                      <Autocomplete
                        options={mapOptions}
                        apiKey={apiKeyShan}
                        onPlaceSelected={(place) => onPlaceSelected(place)}
                      />
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal ">
                      <label>Services offered</label>
                      <div>
                        {services &&
                          services.map((service) => (
                            <div className="service-item pb-2">
                              <Badge bg="light">
                                <input
                                  onChange={pushServices}
                                  type="checkbox"
                                  id={service.IssueID}
                                  value={service.IssueID}
                                />
                                <label for={service.IssueID}>
                                  {service.Title}
                                </label>
                              </Badge>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Accepting Warm Referrals</label>
                      <button 
                        type="button" 
                        class={isAcceptRef ? "btn btn-primary me-2" : "btn btn-outline-primary me-2"}
                        onClick={() => setAcceptRef(true)}
                      >
                        Yes
                      </button>
                      <button 
                        type="button" 
                        class={!isAcceptRef ? "btn btn-danger me-2" : "btn btn-outline-danger me-2"}
                        onClick={() => setAcceptRef(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>

                  <div className="input-element">
                    <div className="input-normal">
                      <label>Description</label>
                      <textarea 
                        className="form-control" 
                        autoFocus
                        name="Description"
                        onChange={onChangeDescription}
                        rows="3"
                        placeholder="Description"
                        value={payload.Description}
                        ></textarea>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className="input-element text-center"
                      style={{ marginRight: 24 }}
                    >
                      <button
                        type="submit"
                        className="primary-button no-animation"
                      >
                        <div className="button-text">
                          {progress ? (
                            <Spinner size="sm" />
                          ) : (
                            "Save Information"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSupportService
