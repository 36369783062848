const mobileRegex = /^(\+61|0)[2-478](\d{8}|\d{4} \d{4}|\d{3} \d{3} \d{3})$/
const countryCode = "+61"
export const isAustralianNumber = (value) => {
  const cleanedValue = value.replace(/\s/g, "");
  return cleanedValue.length > 0 && mobileRegex.test(cleanedValue)
}

export const convertAustralianNumber = (number) => {
  const cleanedNumber = number.replace(/\s/g, "");
  if (isAustralianNumber(cleanedNumber)) {
    let digits = cleanedNumber.replace(/\D/g, "")
    if (digits.indexOf(0) === 0) {
      return countryCode + digits.substr(1)
    } else {
      return "+" + digits
    }
  }
}
