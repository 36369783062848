import React from "react"

import { Button, Modal, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Disclosure } from "@headlessui/react"
import { ArrowLongLeftIcon, ChevronUpIcon } from "@heroicons/react/20/solid"
import {
  FlagIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon
} from "@heroicons/react/24/outline"
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid"

import {
  apiDeletePM,
  apiGetAllPM,
  apiGetPMA,
  apiUpdatePM
} from "../../../core/api"
import { getUser } from "../../../core/plugins/Entry"
import {
  convertAustralianNumber,
  isAustralianNumber
} from "../../../core/plugins/validator"
function ListPropertyManagers() {
  const navigation = useNavigate()

  const routeTo = (route) => {
    navigation(route)
  }
  const [agent, setAgent] = React.useState(false)
  const [sIndex, setSIndex] = React.useState(false)
  const [payload, setPayload] = React.useState(false)
  const [deleteLoader, setDeleteStatus] = React.useState(false);
  const [deleteConfirmationModel, showDeleteConfirmation] = React.useState(false);
  const filter = (e) => {
    setPayload(e.target.value)
    let filtered =
      pmlist && pmlist.filter((item) => item.Name.includes(e.target.value))
    setPMList(filtered)
    if (e.target.value.length === 0) {
      clearFilters()
      setPayload(false)
    }
  }

  const clearFilters = () => {
    setPayload(false)
    apiGetAllPM().then((response) => {
      setPMList(response)
    })
  }

  const [pmlist, setPMList] = React.useState(false)

  React.useEffect(() => {
    apiGetAllPM().then((response) => {
      setPMList(response)
    })

    apiGetPMA(getUser().UUID).then((response) => {
      console.log(response)
      setAgent(response)
    })
  }, [])

  const deletePM = (payload) => {
    setDeleteStatus(true);
    apiDeletePM(payload.PMID)
      .then((response) => {
        if (response) {
          toast.success("Property Manager deleted")
        }

        apiGetAllPM().then((response) => {
          setPMList(response)
          setDeleteStatus(true);
          showDeleteConfirmation(false)
        })
      })
      .catch((error) => {
        toast.error(error?.message)
        setDeleteStatus(true);
        showDeleteConfirmation(false)
      })
  }

  const [showEditModal, setShowEditModal] = React.useState(false)

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  const [editPayload, setEditPayload] = React.useState({
    Email: "",
    UserName: "",
    Mobile: "",
    Name: "",
    PMAID: "",
    PMID: ""
  })

  const [editLoader, setEditLoader] = React.useState(false)

  const submitEdit = (e) => {
    e.preventDefault()
    if (
      editPayload.Mobile.length > 0 &&
      !isAustralianNumber(editPayload.Mobile)
    ) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }

    setEditLoader(true)

    apiUpdatePM({
      Email: editPayload.Email,
      UserName: editPayload.UserName,
      Mobile: convertAustralianNumber(editPayload.Mobile),
      Name: editPayload.Name,
      PMAID: editPayload.PMAID,
      PMID: editPayload.PMID
    }).then((response) => {
      setEditLoader(false)
      if (response) {
        toast.success("Property Manager Updated")
        apiGetAllPM().then((response) => {
          setPMList(response)
        })
      } else {
        toast.error("Error updating property manager")
      }
      handleCloseEditModal()
    })
  }

  const onChangeEditPaylod = (e) => {
    setEditPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const deleteConfirmationModal = () => {
    return (<>
      <Modal 
        show={deleteConfirmationModel} 
        onHide={() => showDeleteConfirmation(false)} 
        backdrop="static" className="verification-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title>Are you sure to delete this Property Manager ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              deleteConfirmationModel && deleteConfirmationModel.Name
            }
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className='input-element'>
            <button onClick={() => deletePM(deleteConfirmationModel)}
              className='btn btn-edit'
              disabled={deleteLoader}
            >
              {deleteLoader ? <Spinner animation='border' size='sm' /> : 'Yes'}
            </button>
            <div className="spacer-sm"></div>
            <button onClick={() => showDeleteConfirmation(false)}
              className='btn btn-delete'>
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>)
  }

  return (
    <>
      {deleteConfirmationModal()}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        className="edit-modal"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          {/* <div className="top-icon">
            <PencilIcon />
          </div> */}
          <div className="spacer-sm"></div>
          <h4>Edit Property Manager</h4>
          <div className="spacer-sm"></div>
          <form onSubmit={submitEdit}>
            <div className="input-element">
              <div className="input-wrapper">
                <input
                  autoFocus
                  value={editPayload.Name}
                  name="Name"
                  type="text"
                  placeholder="Name"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-wrapper">
                <input
                  autoFocus
                  value={editPayload.Email}
                  name="UserName"
                  disabled
                  type="email"
                  placeholder="Email"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element">
              <div className="input-wrapper">
                <input
                  autoFocus
                  value={editPayload.Mobile}
                  name="Mobile"
                  type="text"
                  placeholder="Mobile"
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className="input-element text-center">
              <button type="submit" className="primary-button-small">
                <div className="button-text">
                  {editLoader ? <Spinner size="sm" /> : "Update"}
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="container inner-page ">
        <div className="spacer-lg"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => routeTo("/aa/issues")}
                >
                  <ArrowLongLeftIcon />
                </div>
                <div>
                  <p class="admin-type"> Agency Admin</p>
                  <h2 className="text-left">{agent && agent.CompanyName}</h2>
                </div>
              </div>

              <div className="input-element text-center">
                <button
                  onClick={() => routeTo("/aa/add-pm")}
                  className="primary-button button-add"
                >
                  <div className="button-text"></div>
                  <div className="right-icon">
                    <PlusIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>
              <div className="accordian-list">
                <div className="d-flex sub-title-with-icon align-items-center">
                  <div className="icon-left">
                    <FlagIcon />
                  </div>
                  <h3>Property Managers {!pmlist && <Spinner size="sm" />}</h3>
                </div>

                <div className="">
                  <div>
                    <div className="spacer-sm"></div>
                    <div className="search-bar">
                      <div className="search-input">
                        <input
                          value={payload ? payload : ""}
                          onChange={filter}
                          type="text"
                          placeholder="Search a property manager"
                        ></input>
                        <div className="right-icon" onClick={clearFilters}>
                          {payload && payload.length > 0 ? (
                            <XMarkIcon />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </div>
                      </div>
                    </div>

                    {pmlist &&
                      pmlist.map((item, i) => (
                        <Disclosure
                          as="div"
                          className={
                            sIndex === i ? "acc-wrapper open" : "acc-wrapper"
                          }
                          key={item.Ticket_No}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className="d-flex acc-wrapper-button"
                                onClick={() => setSIndex(i)}
                              >
                                <div>
                                  <span className="text-orange">
                                    {item.Name}
                                  </span>{" "}
                                </div>
                                <ChevronUpIcon
                                  className={`${open ? "rotate-180" : ""}`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="acc-panel">
                                <p>
                                  <span className="text-orange">Email</span>
                                  <br />
                                  <span>{item.Email}</span>
                                </p>

                                <p>
                                  <span className="text-orange">Mobile</span>
                                  <br />
                                  <span>{item.Mobile}</span>
                                </p>

                                <p>
                                  <span className="text-orange">
                                    Property Manager Username
                                  </span>
                                  <br />
                                  <span>{item.UserName}</span>
                                </p>
                                <div className="row justify-content-center">
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <div>
                                      <Button
                                        className="btn-edit"
                                        onClick={() => {
                                          setShowEditModal(true)
                                          setEditPayload(item)
                                        }}
                                      >
                                        <span>
                                          <PencilIcon />
                                        </span>
                                        Edit
                                      </Button>
                                      <Button
                                        className="btn-delete"
                                        // onClick={() => deletePM(item)}
                                        onClick={() => showDeleteConfirmation(item)}
                                      >
                                        <span>
                                          <TrashIcon />
                                        </span>{" "}
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}

                    {pmlist && pmlist.length === 0 && (
                      <div className="empty-list-data">
                        <div className="spacer-sm"></div>
                        <h4>No property managers found</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default ListPropertyManagers
