import React from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { getUser } from "../../core/plugins/Entry"
const DefaultLayout = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  React.useEffect(() => {
    const user = getUser()
    console.log(user)
    if (!user) {
      navigate("/")
      return
    }
  }, [location])

  return <>{children}</>
}

export default DefaultLayout
