import React from "react"

import { Spinner } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Autocomplete from "react-google-autocomplete"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"
import {
  ArrowRightIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline"
import { ArrowLongLeftIcon, CheckCircleIcon } from "@heroicons/react/24/solid"

import {
  apiCreateInquiry2,
  apiGetAllIssues,
  apiGetPM,
  apiGetPMA,
  apiGetSupportServicesDistance
} from "../../../core/api"
import { apiKeyShan, mapOptions } from "../../../core/plugins/config"
import { getUser } from "../../../core/plugins/Entry"
import { isAustralianNumber, convertAustralianNumber  } from "../../../core/plugins/validator"
import { ROUTES } from "../../../core/routes"
const PMRaiseIssue = () => {
  const [issueValue, setIssueValue] = React.useState(false)

  const [step, setStep] = React.useState(0)
  const [currentSS, setCurrentSS] = React.useState(false)

  const prevStep = (step) => {
    setStep(step)
  }

  const selectSupportService = () => {
    if (currentSS) {
      setStep(2)
    } else {
      toast.error("Select a support service")
    }
  }
  const [valueOk, setOk] = React.useState("ok")

  const switchOk = (v) => {
    setOk(v)

    if (v === "ok") {
      setPayload((prevState) => ({
        ...prevState,
        SendSMS: true
      }))
    } else {
      setPayload((prevState) => ({
        ...prevState,
        SendSMS: false
      }))
    }
  }

  const [show, setModal] = React.useState(false)
  const handleClose = () => {
    setModal(false)
  }

  const handleShow = (e) => {
    e.preventDefault()

    submit()
  }

  const navigation = useNavigate()
  const routeTo = (url) => {
    navigation(url)
  }

  const [payload, setPayload] = React.useState({
    TenantID: "",
    IssueID: "",
    IssueTitle: "",
    TenantName: "",
    PMAID: "",
    PMACompany: "",
    PMID: "",
    PMName: "",
    SWorkerID: "",
    SWName: "",
    SWEmail: "",
    SWMobile: "",
    TenantMobile: "",
    TenantEmail: "",
    TenantLocation: "",
    PMMobile: "",
    PMEmail: "",
    SendSMS: true
  })
  const [user_location, setUserLocation] = React.useState(false)
  const onChange = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [additionalInfo, setAdditionalInfo] = React.useState(null);
  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
  };

  const goToNext = () => {
    if (!user_location) {
      toast.error("Please select your location")
    }
    if (payload.IssueTitle === "") {
      toast.error("Please select an issue")
    }
    if (payload.IssueTitle && user_location) {
      setStep(1)
      getProximitySupportWorkers()
    }
  }
  const selectValue = (e) => {
    if (e.target.value !== "") {
      setIssueValue(JSON.parse(e.target.value))
      setPayload((prevState) => ({
        ...prevState,
        IssueTitle: e.target.value
      }))
    } else {
      toast.error("Please select an issue")
    }
    if (user_location) {
      return
    } else {
      toast.error("Please select your location")
    }
  }
  const updatePayload = (ss, i) => {
    setCurrentSS(ss)
    setIndex(i)
  }

  const [sIndex, setIndex] = React.useState(false)

  const onPlaceSelected = (place) => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address)
    }
  }

  const onPlaceSelectedTenent = (place) => {
    if (place && place?.formatted_address) {
      setPayload((prevState) => ({
        ...prevState,
        TenantLocation: place.formatted_address
      }))
    }
  }

  const mapServices = (current_issues) => {
    let c_issues = JSON.parse(current_issues)
    let current_services = []
    if (issues && c_issues.length) {
      issues &&
        issues.forEach((s) => {
          c_issues.forEach((i) => {
            if (s.IssueID === i) {
              current_services.push(s)
            }
          })
        })

      return current_services
    } else {
      return []
    }
  }

  const [progress, setProgress] = React.useState(false)
  const submit = (e) => {
    e.preventDefault()
    if (
      payload.TenantMobile.length > 0 &&
      !isAustralianNumber(payload.TenantMobile)
    ) {
      toast.error(
        "Please enter a valid Australian phone number eg +61234567890 / 0298765432"
      )
      return
    }
    setProgress(true)
    apiCreateInquiry2({
      TenantID: "1",
      IssueID: issueValue.IssueID,
      IssueTitle: issueValue.Title,
      TenantName: payload.TenantName,
      PMAID: currentPMA.PMAID,
      PMACompany: currentPMA.CompanyName ? currentPMA.CompanyName : "N/A",
      PMID: getUser().UUID,
      PMName: getUser().attributes.name,
      SWorkerID: currentSS.SWorkerID,
      SWName: currentSS.Name,
      SWEmail: currentSS.Email,
      SWMobile: currentSS.Mobile,
      TenantMobile: convertAustralianNumber(payload.TenantMobile),
      TenantEmail: payload.TenantEmail,
      TenantLocation: user_location,
      PMMobile: currentPM.Mobile,
      PMEmail: getUser().attributes.email,
      SendSMS: currentSS.IsAcceptRef ? payload.SendSMS : false,
      ContextualNotes: additionalInfo,
    })
      .then((response) => {
        if (response) {
          setProgress(false)
          toast.success("Issue created successfully")
          setModal(true)
          setCurrentSS(false)
          setUserLocation(false)
          setIssueValue(false)
        } else {
          toast.error("Error creating issue")
          setProgress(false)
        }
      })
      .finally(() => {
        setProgress(false)
      })
  }
  const [issues, setIssues] = React.useState(false)
  const [support_serivces, setSupportServices] = React.useState(false)
  const [currentPMA, setCurrentPMA] = React.useState(false)
  const [currentPM, setCurrentPM] = React.useState(false)
  React.useEffect(() => {
    apiGetAllIssues().then((response) => {
      if (response) {
        setIssues(response)
      }
    })

    apiGetPM(getUser().UUID).then((response) => {
      if (response) {
        setCurrentPM(response)

        apiGetPMA(response.PMAID).then((response) => {
          if (response) {
            setCurrentPMA(response)
          }
        })
      }
    })
  }, [])

  const [loading_proximity, setLoadingProximity] = React.useState(false)
  const getProximitySupportWorkers = () => {
    setLoadingProximity(true)
    apiGetSupportServicesDistance({
      TenetLocation: user_location,
      IssueID: issueValue.IssueID
    })
      .then((response) => {
        if (response) {
          setLoadingProximity(false)
          
          const updatedResponse = response.map((item) => {
            if (!item.hasOwnProperty('IsAcceptRef')) {
              return { ...item, IsAcceptRef: false };
            }
            return item;
          });
          
          // const groupedByLocation = updatedResponse.reduce((acc, obj) => {
          //   const key = obj.Location;
          //   if (!acc[key]) {
          //     acc[key] = [];
          //   }
          //   acc[key].push(obj);
          //   return acc;
          // }, {});
          
          // const sortedArray = Object.values(groupedByLocation).flatMap(group => {
          //   return group.sort((a, b) => {
          //     return b.IsAcceptRef - a.IsAcceptRef;
          //   });
          // });

          const trueAcceptRef = updatedResponse.filter(item => item.IsAcceptRef);
          const falseAcceptRef = updatedResponse.filter(item => !item.IsAcceptRef);
          const sortedArray = [...trueAcceptRef, ...falseAcceptRef]

          setSupportServices(sortedArray)
        }
      })
      .finally(() => {
        setLoadingProximity(false)
      })
  }

  const [validator, setValidator] = React.useState({
    TenantMobile: false,
    TenantEmail: false,
    TenantName: false
  })

  React.useEffect(() => {
    if (step === 0) {
      setCurrentSS(false)
      setUserLocation(false)
      setIssueValue(false)
    }
  }, [step])

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {currentSS.Description ?? "No Description found!"}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-small"
      >
        <Modal.Body>
          <div className="spacer-lg"></div>
          <div className="top-icon">
            <CheckCircleIcon />
          </div>
          <div className="spacer-sm"></div>
          <h4>Done</h4>
          <p>
            {valueOk === "ok" ?
              "The consent requested has been sent to the tenant" : 
              "Support service details have been shared with the tenant"}
          </p>
          <div className="spacer-lg"></div>
          <div className="input-element text-center">
            <button
              className="primary-button-small"
              onClick={() => {
                handleClose()
                routeTo(ROUTES.PM_DASHBOARD)
              }}
            >
              <div className="button-text">Ok</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="container inner-page ">
        <div className="spacer-sm"></div>
        <div className="d-flex flex-column">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 position-relative">
              <div className="d-flex align-items-center">
                <div
                  className="left-round-button"
                  onClick={() => {
                    routeTo(ROUTES.PM_DASHBOARD)
                  }}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className="text-left">Raise an issue</h2>
              </div>

              <div className="steps">
                {step === 0 && <img src="/images/step-0.png" />}
                {step === 1 && <img src="/images/step-1.png" />}
                {step === 2 && <img src="/images/step-2.png" />}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="spacer-lg"></div>
              <div className="d-flex justify-content-center">
                {!issues && <Spinner animation="border" size="sm" />}
              </div>
              {step === 0 && issues && (
                <>
                  <div className="step-0">
                    <div className="input-element">
                      <div className="input-normal">
                        <label>Select Location</label>
                        <Autocomplete
                          options={mapOptions}
                          defaultValue=""
                          apiKey={apiKeyShan}
                          onPlaceSelected={(place) => onPlaceSelected(place)}
                        />
                      </div>
                    </div>
                  </div>{" "}
                  <div className="spacer-md"></div>
                  <div className="step-0">
                    <div className="input-element">
                      <label>Select an Issue</label>
                      {issues && (
                        <select onChange={selectValue}>
                          <option value="">Select an Issue</option>
                          {issues &&
                            issues.map((issue) => (
                              <option
                                key={issue.IssueID}
                                value={JSON.stringify(issue)}
                              >
                                {issue.Title}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="spacer-md"></div>
                  <div className="step-0">
                    <div className="input-element">
                      <label>Additional infomation</label>
                      <textarea
                        className="col-lg-12 col-md-12"
                        name="additionalInfo"
                        placeholder="Provide additional context or details..."
                        value={additionalInfo}
                        onChange={handleAdditionalInfoChange}
                        rows="10"
                        style={{    
                          "background": "#f1f1f1",
                          "border": "thin solid #d8d8d8",
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="input-element text-center">
                      <button className="primary-button" onClick={goToNext}>
                        <div className="button-text">Next</div>
                        <div className="right-icon">
                          <ArrowRightIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex justify-content-center">
                {loading_proximity && <Spinner size="sm" />}
              </div>

              {support_serivces && step === 1 && (
                <>
                  {
                  support_serivces && support_serivces.length > 0 && 
                  <div className="support-services-info">
                  Support services for
                  <p>{user_location && user_location}</p>
                  <p>{issueValue && issueValue.Title}</p>
                </div>
                }

                {
                  support_serivces && support_serivces.length === 0 &&  
                  <div className="support-services-info">
                No support service currently available
                  <p>{user_location && user_location}</p>
                  <p>{issueValue && issueValue.Title}</p>
                </div>
                }
                  
                  {support_serivces && support_serivces.length > 0 && support_serivces.map((ss, i) => (
                    <Disclosure
                      as="div"
                      className={
                        i === sIndex ? "acc-wrapper open" : "acc-wrapper"
                      }
                    >
                      {({ open = i === sIndex }) => (
                        <>
                          <Disclosure.Button
                            className="d-flex acc-wrapper-button"
                            onClick={() => updatePayload(ss, i)}
                          >
                            <div className="d-flex align-items-center">
                              {i === sIndex && (
                                <div className="icon-check-circle-select">
                                  <CheckCircleIcon />
                                </div>
                              )}
                              {i !== sIndex && (
                                <div className="icon-check-circle"></div>
                              )}
                              <span className="text-orange">
                                {ss.Name} - {ss.Location}
                              </span>{" "}
                            </div>
                            <ChevronUpIcon
                              className={`${open ? "rotate-180" : ""}`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="acc-panel">
                            <p>
                              <span className="text-orange">Name</span>
                              <br />
                              <span>{ss.Name}</span>
                            </p>

                            <p>
                              <span className="text-orange">Email</span>
                              <br />
                              <span>{ss.Email}</span>
                            </p>

                            <p>
                              <span className="text-orange">Address</span>
                              <br />
                              <span>{ss.Location}</span>
                            </p>

                            <p>
                              <span className="text-orange">Mobile</span>
                              <br />
                              <span>{ss.Mobile}</span>
                            </p>

                            <p>
                              <span className="text-orange">
                                Support services
                              </span>
                              <br />

                              <span>
                                {ss.IssueID &&
                                  mapServices(ss.IssueID).map((s) => (
                                    <>
                                      {s.Title} <br />
                                    </>
                                  ))}
                              </span>
                            </p>

                            <p>
                              <span className="text-orange">Accept Referrals</span>
                              <br />
                              <span>{ss.IsAcceptRef ? "Yes" : "No"} </span>
                            </p>

                            <p>
                              <div className="icon-inf-desc">
                                <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                  <InformationCircleIcon />
                                </OverlayTrigger>
                              </div>
                            </p>

                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                  <div className="spacer-lg"></div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className="input-element text-center"
                      style={{ marginRight: 24 }}
                    >
                      <button
                        className="primary-button"
                        onClick={() => prevStep(0)}
                      >
                        <div className="button-text">Previous</div>
                      </button>
                    </div>
                    <div className="input-element text-center">
                      <button
                        className="primary-button"
                        onClick={selectSupportService}
                      >
                        <div className="button-text">Next</div>
                        <div className="right-icon">
                          <ArrowRightIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {!loading_proximity &&
                issueValue &&
                !support_serivces &&
                step === 1 && (
                  <>
                    <div className="no-services text-center">
                      <div className="icon-top">
                        <InformationCircleIcon />
                      </div>
                      <p>No support services currently available</p>
                    </div>
                    <div className="spacer-lg"></div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="input-element text-center"
                        style={{ marginRight: 24 }}
                      >
                        <button
                          className="primary-button"
                          onClick={() => prevStep(0)}
                        >
                          <div className="button-text">Previous</div>
                        </button>
                      </div>
                      <div className="input-element text-center"></div>
                    </div>
                  </>
                )}

              {step === 2 && (
                <>
                  <form onSubmit={submit}>
                    <div
                      className={`input-element ${
                        validator.TenantName ? "error" : ""
                      }`}
                    >
                      <div className="input-normal">
                        <label>
                          Tenant Name
                          {validator.TenantName ? (
                            <span>
                              <small>Please enter Tenant name</small>
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          required
                          name="TenantName"
                          autoFocus
                          type="text"
                          placeholder="Name"
                          onChange={onChange}
                        ></input>
                      </div>
                    </div>

                    <div
                      className={`input-element ${
                        validator.TenantMobile ? "error" : ""
                      }`}
                    >
                      <div className="input-normal">
                        <label>Phone number</label>
                        <input
                          required
                          name="TenantMobile"
                          type="text"
                          placeholder="Phone number"
                          onChange={onChange}
                        ></input>
                      </div>
                    </div>

                    <div className="input-element">
                      <div className="input-normal">
                        <label>Email</label>
                        <input
                          required
                          name="TenantEmail"
                          type="email"
                          placeholder="Email address"
                          onChange={onChange}
                        ></input>
                      </div>
                    </div>

                    {currentSS.IsAcceptRef && (<>
                      <p
                        style={{
                          fontWeight: 600
                        }}
                      >
                        Send an SMS to the tenant to confirm the tenant's consent to sharing the information with a third party
                      </p>

                      <div className="input-element d-flex justify-content-center">
                        <div
                          className="input-element-ok d-flex align-items-center"
                          onClick={() => switchOk("ok")}
                        >
                          {valueOk === "ok" && <CheckCircleIcon />}
                          {valueOk !== "ok" && (
                            <div className="check-circle"></div>
                          )}
                          <label for="ok">Yes</label>
                        </div>
                        &nbsp;
                        <div
                          className="input-element-ok d-flex align-items-center"
                          onClick={() => switchOk("not-ok")}
                        >
                          {valueOk === "not-ok" && <CheckCircleIcon />}
                          {valueOk !== "not-ok" && (
                            <div className="check-circle"></div>
                          )}
                          <label for="Dont">No</label>
                        </div>
                      </div>
                    </>)}

                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="input-element text-center"
                        style={{ marginRight: 24 }}
                      >
                        <button
                          className="primary-button"
                          onClick={() => prevStep(1)}
                        >
                          <div className="button-text">Previous</div>
                        </button>
                      </div>
                      <div className="input-element text-center">
                        <button
                          type="submit"
                          className="primary-button"
                          disabled={progress}
                        >
                          <div className="button-text">
                            {progress ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Finish"
                            )}
                          </div>
                          <div className="right-icon">
                            <ArrowRightIcon />
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <a
            className="d-block"
            href="/"
            style={{
              fontSize: 10,
              color: "#fff"
            }}
          >
            PM Assist
          </a>
        </div>
      </div>
    </>
  )
}

export default PMRaiseIssue
